<mat-form-field
  class="flex-auto gt-xs:pr-3 w-full"
  (click)="edit()"
  (focus)="edit()"
  *ngIf="!multiple"
  [hideRequiredMarker]="true"
>
  <mat-label>
    <span
      class="block text-ellipsis overflow-hidden pointer-events-auto"
      [matTooltip]="fieldDef.label"
      tooltipIfEllipsis
    >
      {{ fieldDef.label }}
    </span>
  </mat-label>
  <div class="w-full">
    <mat-select
      (selectionChange)="onSelectionChange()"
      [(ngModel)]="value"
      (blur)="onBlur()"
      [disabled]="isDisabled || readonly"
      [required]="this.isRequired"
      #selectOption="ngModel"
    >
      <mat-option [value]="''"></mat-option>
      <mat-option *ngFor="let option of filteredOptions" [value]="option.id">
        {{ option?.name || option?.full_name | formatSnakeCase }}</mat-option
      >
    </mat-select>
  </div>
  <mat-error class="w-full" *ngIf="selectOption.errors?.['required']">{{ fieldDef.label }} is required.</mat-error>
</mat-form-field>

<mat-form-field class="flex-auto gt-xs:pr-3 w-full" *ngIf="multiple">
  <mat-label>
    <span
      class="block text-ellipsis overflow-hidden pointer-events-auto"
      [matTooltip]="fieldDef.label"
      tooltipIfEllipsis
      >{{ fieldDef.label }}</span
    >
  </mat-label>
  <div class="w-full" [matTooltip]="multiSelectOptionsText">
    <mat-select multiple [(ngModel)]="value" (openedChange)="openedChange($event)" [disabled]="isDisabled || readonly">
      <mat-option *ngFor="let option of filteredOptions" [value]="option.id">
        {{ option?.name || option?.full_name | formatSnakeCase }}
      </mat-option>
    </mat-select>
  </div>
</mat-form-field>
