<div class="w-full h-full flex flex-col items-center justify-center gap-4">
  <div class="icons-grid grid grid-cols-2 gap-2">
    <ts-icon class="border-black-300 border-2 p-2" icon="ts_chart-bar" color="light-gray" size="mid-small" />
    <ts-icon class="border-black-300 border-2 p-2" icon="ts_chart-line" color="light-gray" size="mid-small" />
    <ts-icon class="border-black-300 border-2 p-2" icon="ts_chart-pie-alt" color="light-gray" size="mid-small" />
    <ts-icon class="p-1" [icon]="icon" color="orange" size="medium" />
  </div>
  <div class="flex flex-col items-center justify-center gap-1">
    <p class="text-center text-2xl font-semibold" *ngIf="title">{{ title }}</p>
    <p *ngIf="title && subtitle">{{ subtitle }}</p>
  </div>
  <ts-button *ngIf="buttonConfig" (click)="buttonConfig.actionFn()">{{ buttonConfig.label }}</ts-button>
</div>
