import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { switchMap, tap } from 'rxjs/operators';
import { StateBase } from 'app/state/state-base';
import { getDateRange, guid } from 'app/shared/Utils/common.utils';
import { ReportingService } from '../reporting.service';
import GraphqlService from 'app/services/graphql.service';
import { generateDownloadableFile, getAllowedFields } from 'app/shared/functions';
import { GraphqlConnection, GraphqlResultInfo, GraphqlResultPageInfo } from 'app/shared/models/graphql';
import { BuildChartData, DrillData, Graphql, Report, SummaryFormula as SummaryFormulaActions } from './actions';
import { ReportLayout, ReportType, ReportTypeQueryParamValues } from '../../reports/reports.types';
import {
  CreateReportModel,
  ReportLayoutQueryParams,
  ReportLayoutChart,
  ReportLayoutDefinition,
  TimeSeriesCadenceOptions,
  ReportLayoutSummaryFormula,
} from '../reporting.types';
import { FieldDefsState } from 'app/state/fields/state';
import { RelatedToType } from 'app/shared/enums';
import { sortByProp } from '../reporting.functions';
import { PaginatedResource } from '../../../shared/models/paginated-resource';
import { UserService } from 'app/layout/common/user/user.service';
import { SummaryFormulaService, SummaryFormula } from '@services/summary-formula.service';
import { Observable } from 'rxjs';
import { AccountState } from '@state/account/state';
import { FeatureFlag } from '@core/enums';

const SUBTOTAL_VALUE_TEXT = 'Subtotal';
const TOTAL_VALUE_TEXT = 'Total';

export type LayoutsPage = PaginatedResource<ReportLayout<ReportLayoutDefinition>>;

export interface ReportingStateModel {
  layouts: LayoutsPage;
  selectedLayout: ReportLayout<ReportLayoutDefinition>;
  chartData: ReportLayoutChart[];
  graphqlResults: {
    rows: any[];
    pageInfo?: GraphqlResultPageInfo;
    timeSeries?: {
      columns: string[];
      isGrouped?: boolean;
    };
  };
  drillData: { rows: any[]; pageInfo?: GraphqlResultPageInfo };
  summaryFormulas: SummaryFormula[];
}

@State<ReportingStateModel>({
  name: new StateToken<ReportingStateModel>('reportingState'),
  defaults: {
    layouts: null,
    selectedLayout: null,
    chartData: null,
    graphqlResults: null,
    drillData: null,
    summaryFormulas: [],
  },
})
@Injectable()
export class ReportingState extends StateBase {
  constructor(
    protected readonly store: Store,
    private readonly reportingService: ReportingService,
    private readonly summaryFormulaService: SummaryFormulaService,
    private readonly graphqlService: GraphqlService,
    private readonly _snackBar: MatSnackBar,
    private readonly userService: UserService,
  ) {
    super(store);
  }

  @Selector()
  static getLayouts(state: ReportingStateModel): ReportLayout<ReportLayoutDefinition>[] {
    return state.layouts.results;
  }

  @Selector()
  static getLayoutsPage(state: ReportingStateModel): LayoutsPage {
    return state.layouts;
  }

  @Selector()
  static getSelectedLayout(state: ReportingStateModel): ReportLayout<ReportLayoutDefinition> {
    return state.selectedLayout;
  }

  @Selector()
  static getChartData(state: ReportingStateModel): any {
    return state.chartData;
  }

  @Selector()
  static getDrillData(state: ReportingStateModel): any {
    return state.drillData;
  }

  @Selector()
  static getGraphqlResults(state: ReportingStateModel) {
    return state.graphqlResults;
  }

  @Selector()
  static getSummaryFormulas(state: ReportingStateModel) {
    return state.summaryFormulas;
  }

  @Action(Report.Delete)
  deleteReport(context: StateContext<ReportingStateModel>, { reportId }: { reportId: number }) {
    return this.reportingService.delete<any>(reportId, Number(this.account.id)).pipe(
      tap(() => {
        const state = context.getState();
        const requestedPageAfterDelete =
          state.layouts?.results?.length === 1 ? state.layouts?.page - 1 : state.layouts?.page;

        this.store.dispatch(
          new Report.Get({
            report_types: [ReportTypeQueryParamValues.SingleObject, ReportTypeQueryParamValues.TimeSeries],
            ...this.addProvidedPageParamsOrFallback(requestedPageAfterDelete, state.layouts?.per_page),
          }),
        );
      }),
    );
  }

  @Action(Report.Get)
  getReports(context: StateContext<ReportingStateModel>, { filter }: { filter: ReportLayoutQueryParams }) {
    const requestParams: ReportLayoutQueryParams = {
      ...filter,
      ...this.addProvidedPageParamsOrFallback(filter?.page, filter?.per_page),
      account_id: Number(this.account.id),
    };

    if (filter?.created_by_me) {
      requestParams.created_by_me = true;
    }

    if (filter?.favorites) {
      requestParams.favorites = true;
    }

    return this.reportingService.getReports<any>({ ...requestParams }).pipe(
      tap((response) => {
        this.reportingService.updateReportBuilderOptionsBadges({
          allReportsCount: response?.all_reports?.count ?? 0,
          favoritesCount: response?.favorites?.count ?? 0,
          byMeCount: response.created_by_me.count ?? 0,
        });

        const state = context.getState();
        context.setState({
          ...state,
          layouts: {
            ...response,
          },
        });
      }),
    );
  }

  @Action(Report.GetById)
  getReportById(
    context: StateContext<ReportingStateModel>,
    { id, refreshChart }: { id: number; refreshChart: boolean },
  ) {
    return this.reportingService.getReportById<any>(id, Number(this.account.id)).pipe(
      tap((reportLayout) => {
        const selectedLayout: ReportLayout<ReportLayoutDefinition> = {
          ...reportLayout,
          definition: JSON.parse(reportLayout.definition),
          filters: JSON.parse(reportLayout.filters),
        };

        const fieldDefs = this.store.selectSnapshot(FieldDefsState.getFieldDefs);

        const allowedFieldsInReport = getAllowedFields(fieldDefs, selectedLayout.definition.related_to_type, true)
          .filter(({ name }) => selectedLayout.definition.columns.includes(name))
          .map(({ name }) => name);

        selectedLayout.definition.columns = selectedLayout?.definition?.columns?.filter((name) =>
          allowedFieldsInReport.includes(name),
        );

        context.patchState({ selectedLayout });

        if (selectedLayout.definition.visualizations?.length) {
          this.store.dispatch(
            new BuildChartData.Action(
              {
                reportLayoutId: reportLayout.id,
                charts: selectedLayout.definition.visualizations,
              },
              {
                refreshChart,
              },
            ),
          );
        }
      }),
    );
  }

  @Action(Report.Create)
  createReport(context: StateContext<ReportingStateModel>, { model }: { model: CreateReportModel }) {
    let filters: any = { fjson: '[]', sort: [] };
    const definition: ReportLayoutDefinition = {
      related_to_type: model.relatedToType,
      visualizations: [],
      columns: [],
    };
    const isTimeSeries = model.report_type === ReportType.TimeSeries;

    if (isTimeSeries) {
      definition.cadence = TimeSeriesCadenceOptions.Month;
      definition.groupBy = [];
      filters = { ...filters, timeSeries: { startDate: null, endDate: null } };
    }

    if (model.relatedToType === RelatedToType.Trail) {
      definition.trailInfo = {
        target_field: 'project_stage_id',
        related_to_type: RelatedToType.Project,
      };
    }

    return this.reportingService
      .create<any>({
        name: model.name,
        account_id: Number(this.account.id),
        report_type: model.report_type,
        definition: JSON.stringify(definition),
        filters: JSON.stringify(filters),
      })
      .pipe(
        tap((reportLayout) => {
          const state = context.getState();
          context.setState({
            ...state,
            selectedLayout: {
              ...reportLayout,
              definition: JSON.parse(reportLayout.definition),
              filters: JSON.parse(reportLayout.filters),
            },
          });
        }),
      );
  }

  @Action(Report.Update)
  updateReport(
    context: StateContext<ReportingStateModel>,
    { model, options }: { model: ReportLayout<any>; options: { dispatchBuildChart: boolean; refreshChart: boolean } },
  ) {
    const payload = {
      ...model,
      account_id: Number(this.account.id),
      definition: JSON.stringify({
        ...model.definition,
        visualizations: model.definition.visualizations.map((visualization) => ({
          ...visualization,
          title: model.name,
        })),
      }),
      filters: model.filters,
    };
    const updateState = (reportLayout) => {
      const state = context.getState();

      const newState = {
        ...state,
        selectedLayout: {
          ...reportLayout,
          definition: JSON.parse(reportLayout.definition),
          filters: JSON.parse(reportLayout.filters),
        },
      };

      context.setState(newState);

      this.store.dispatch(
        new BuildChartData.Action(
          {
            reportLayoutId: reportLayout.id,
            charts: newState.selectedLayout.definition.visualizations,
          },
          { refreshChart: options.refreshChart ?? false },
        ),
      );
    };

    if (this.store.selectSnapshot(AccountState.getAccount)?.features.includes(FeatureFlag.SUMMARY_FORMULAS)) {
      let reportFormula = model.report_layout_summary_formulas.find((x) => x._destroy);
      if (model.report_layout_summary_formulas.find((x) => x.field === reportFormula?.field && !x._destroy)) {
        return this.toggleBetweenFormulas(payload, reportFormula).pipe(tap(updateState));
      }
    }

    return this.reportingService.update<any>(payload).pipe(tap(updateState));
  }

  @Action(Report.Duplicate)
  duplicateReport(
    context: StateContext<ReportingStateModel>,
    { model: { id } }: { model: ReportLayout<ReportLayoutDefinition> },
  ) {
    return this.reportingService.getReportById(id, this.accountId).pipe(
      switchMap((originalReport: ReportLayout<ReportLayoutDefinition>) => {
        originalReport.name = `${originalReport.name} - (Copy)`;
        delete originalReport.id;
        const parsedDefinition: ReportLayoutDefinition = JSON.parse(originalReport.definition as unknown as string);

        if (parsedDefinition.visualizations?.length) {
          parsedDefinition.visualizations.forEach((visualization) => {
            visualization.id = guid();
            visualization.title = `${visualization.title} - (Copy)`;
          });

          originalReport.definition = JSON.stringify(parsedDefinition) as any;
        }

        originalReport.account_id = Number(this.account.id);

        return this.reportingService.create<any>(originalReport).pipe(
          tap((reportLayout) => {
            const state = context.getState();
            const newState = {
              ...state,
              selectedLayout: {
                ...reportLayout,
                definition: JSON.parse(reportLayout.definition),
                filters: JSON.parse(reportLayout.filters),
              },
            };
            context.setState(newState);
          }),
        );
      }),
    );
  }

  @Action(Report.ClearSelected)
  clearSelectedReport(context: StateContext<ReportingStateModel>) {
    context.setState({ ...context.getState(), selectedLayout: null, chartData: null, graphqlResults: null });
  }

  @Action(BuildChartData.Action)
  buildChartData(
    context: StateContext<ReportingStateModel>,
    payload: { buildChartDataParams: BuildChartData.Params; options: { refreshChart: boolean } },
  ) {
    const {
      buildChartDataParams: { reportLayoutId, charts },
      options,
    } = payload;

    return this.reportingService.buildChartData(reportLayoutId, charts, options.refreshChart ?? false).pipe(
      tap((chartData) => {
        const state = context.getState();
        context.setState({
          ...state,
          chartData: chartData.reduce((p, c) => [...p, ...c], []),
        });
      }),
    );
  }

  @Action(DrillData.Action)
  drillData(context: StateContext<ReportingStateModel>, { grapqlParams }: { grapqlParams: Graphql.Params }) {
    return this.graphqlService
      .query(Number(this.account.id), {
        connection: grapqlParams.connection,
        fields: grapqlParams.fields,
        filter: grapqlParams.filter,
        fieldDefs: grapqlParams.fieldDefs,
        options: grapqlParams.options,
      })
      .pipe(
        tap((result) => {
          const { edges, pageInfo } = result;
          const state = context.getState();

          context.setState({
            ...state,
            drillData: {
              rows: edges.map(({ node }) => node),
              pageInfo,
            },
          });
        }),
      );
  }

  @Action(Graphql.Action)
  graphql(context: StateContext<ReportingStateModel>, { graphqlParams }: { graphqlParams: Graphql.Params }) {
    let fields = [...graphqlParams.fields.filter((field) => field !== 'id'), 'id', 'name'];

    const params = { ...graphqlParams, fields };

    if (graphqlParams.relatedToType === RelatedToType.Project) {
      fields = [...fields.filter((field) => field !== 'pipeline_id'), 'pipeline_id'];

      fields = [...(fields.filter((field) => !['title', 'name'].includes(field)) ?? []), 'title'];
    }

    if (graphqlParams.relatedToType === RelatedToType.Engagement) {
      fields = fields.filter((name) => name !== 'name');
    }

    if (/*this.isParamsForProjectOrCO(graphqlParams) && */ graphqlParams.groupBy?.length) {
      params.format = 'json';
    }

    params.fields = fields;

    if (graphqlParams.connection.startsWith('co_')) {
      params.fields = params.fields.filter((field) => field !== 'name');
    }

    if (graphqlParams.connection !== GraphqlConnection.timeSeries) {
      delete params.relatedToType;
    } else {
      if (params.groupBy?.length) {
        params.format = graphqlParams.format ?? 'time_series_json';
      }
    }

    return this.graphqlService.query(Number(this.account.id), params, graphqlParams.exportOptions).pipe(
      tap((result) => {
        if (graphqlParams.exportOptions) {
          let castedResult = result as any;
          if (castedResult?.message) {
            this._snackBar.open(castedResult.message, '', { duration: 3000 });
          } else {
            generateDownloadableFile(
              castedResult,
              graphqlParams.exportOptions.format,
              graphqlParams.exportOptions.fileName,
            );
          }
        } else {
          const { edges, pageInfo } = result;
          const rows = (edges ?? []).map(({ node }) => node as any);

          const graphqlResults = {
            rows,
            pageInfo,
            timeSeries: null,
          };

          if (
            graphqlParams.connection ===
              GraphqlConnection.timeSeries /*(graphqlParams.connection === 'projects' || graphqlParams.connection.startsWith('co_')) &&*/ ||
            params.format === 'json'
          ) {
            this.transformTimeSeriesResults(graphqlParams, graphqlResults, result);
          }

          const state = context.getState();

          context.setState({
            ...state,
            graphqlResults,
          });
        }
      }),
    );
  }

  @Action(Report.ToggleFavorite)
  toggleFavorite(context: StateContext<ReportingStateModel>, { id }: { id: number }) {
    return this.userService.toggleFavoriteRecord<ReportLayout<any>>(Number(this.accountId), 'ReportLayout', id).pipe(
      tap(() => {
        const state = context.getState();
        context.patchState({
          layouts: {
            ...state.layouts,
            results: state.layouts.results.map((layout) => {
              if (layout.id === id) {
                layout.is_favorite = !layout.is_favorite;
              }
              return layout;
            }),
          },
        });
      }),
    );
  }

  @Action(SummaryFormulaActions.Get)
  getSummaryFormulas(context: StateContext<ReportingStateModel>) {
    return this.summaryFormulaService.getByAccountId(this.accountId).pipe(
      tap((response) => {
        context.setState({
          ...context.getState(),
          summaryFormulas: response,
        });
      }),
    );
  }

  private transformTimeSeriesResults(
    graphqlParams: Graphql.Params,
    graphqlResults: {
      rows: any[];
      pageInfo: GraphqlResultPageInfo;
      timeSeries: any;
    },
    rawResult: GraphqlResultInfo<any>,
  ): void {
    let dates = new Set<string>([]);
    const fields = graphqlParams.fields.map((f) => f.substring(0, f.indexOf('(')));
    if (graphqlParams.cadence === TimeSeriesCadenceOptions.Day) {
      dates = new Set<string>(getDateRange(graphqlParams.startDate, graphqlParams.endDate));
    } else if (graphqlParams.cadence === TimeSeriesCadenceOptions.Month) {
      dates = new Set<string>(getDateRange(graphqlParams.startDate, graphqlParams.endDate, true));
    } else {
      graphqlResults.rows.forEach((curr) => {
        Object.keys(curr)
          .filter((key) => fields.includes(key))
          .forEach((customFieldName) => {
            curr[customFieldName].reduce((prevTSData: any, currTSData: any) => dates.add(currTSData.date), null);
          });
      });
    }

    if (graphqlParams.groupBy?.length) {
      const hasNestedFiltersOn = this.store
        .selectSnapshot(AccountState.getAccount)
        .features.includes(FeatureFlag.NESTED_FILTERS);
      const fieldDefs = getAllowedFields(
        this.store.selectSnapshot(FieldDefsState.getFieldDefs),
        graphqlParams.relatedToType,
        hasNestedFiltersOn,
      );
      const columns = rawResult.timeSeries.headers.map((headerStr) => {
        // it's for backward compatibility
        headerStr =
          Object.keys(headerStr).length === 1
            ? Object.keys(headerStr)[0] === ''
              ? Object.values(headerStr)[0]
              : Object.keys(headerStr)[0]
            : headerStr;

        let field = fieldDefs.find((fieldDef) => fieldDef.name === (headerStr === 'name' ? 'title' : headerStr)) ?? {
          name: headerStr,
          label: headerStr === 'time_series_field' ? 'Time Series Field' : headerStr,
        };

        if (headerStr.includes('.')) {
          const parentField = fieldDefs.find((x) => x.name === headerStr.split('.')[0]);
          field.label = `${parentField.label} ${field.label}`;
        }

        return field;
      });

      const rows = this.transformForTimeSeriesJson(
        rawResult.timeSeries.data,
        columns.map((x) => x.name),
      );

      graphqlResults.rows = rows.edges.map((x) => x.node);

      const hasSubtotal = rawResult.timeSeries.data.some((row) =>
        row.some((rowValue) => rowValue === SUBTOTAL_VALUE_TEXT),
      );

      if (hasSubtotal) {
        graphqlResults.rows = graphqlResults.rows.map((row: any) => {
          const isSubtotalLine = Object.values(row).includes(SUBTOTAL_VALUE_TEXT);
          if (isSubtotalLine) {
            row.fieldDefName = graphqlResults.rows[0]?.fieldDefName;
            return row;
          } else {
            return row;
          }
        });
      }

      graphqlResults.timeSeries = { columns, isGrouped: true };
      return;
    } else {
      graphqlResults.rows = graphqlResults.rows.reduce((prev: any, curr: any) => {
        prev.push({ ...curr, id: curr.id, name: curr.name ?? curr.title });

        const fields = graphqlParams.fields.map((f) => f.substring(0, f.indexOf('(')));

        Object.keys(curr)
          .filter((key) => fields.includes(key))
          .forEach((customFieldName) => {
            const datesData = curr[customFieldName].reduce((prevTSData: any, currTSData: any) => {
              const date = currTSData.date;
              dates.add(date);
              return { ...prevTSData, [date]: currTSData.value };
            }, {} as any);

            prev.push({ ...curr, id: null, parentId: curr.id, name: customFieldName, ...datesData });
          });

        return prev;
      }, [] as any);
    }

    graphqlResults.timeSeries = {
      columns: this.buildTimeSeriesColumnsArray(
        graphqlParams.cadence,
        dates,
        graphqlParams.fields.filter((x) => x.indexOf('cf_name: ') < 0),
      ),
    };
  }

  private transformForTimeSeriesJson(data: any[][], headers: string[]): GraphqlResultInfo<any> {
    const timeSerieFieldIndex = headers.findIndex((x) => x === 'time_series_field');
    const result: GraphqlResultInfo<any> = {
      edges: data
        // remove total row; remove this code once backend paginates total values
        .filter((row) => row[0] !== TOTAL_VALUE_TEXT)
        .map((x) => {
          return x.reduce((p, c, i) => {
            return { ...p, [headers[i]]: c, fieldDefName: x[timeSerieFieldIndex] };
          }, {});
        })
        .map((x, i) => {
          return { cursor: String(i), node: x };
        }),
      pageInfo: null,
    };

    return result;
  }

  private buildTimeSeriesColumnsArray(cadence: string, dates: Set<string>, fields: string[]): string[] {
    let result = [] as string[];

    switch (cadence) {
      case TimeSeriesCadenceOptions.Quarter:
        result = [...dates]
          .map((x) => ({ q: x.substring(5), y: x.substring(0, 4) }))
          .sort((a, b) => sortByProp(a, b, 'q'))
          .sort((a, b) => sortByProp(a, b, 'y'))
          .map((x) => `${x.y}-${x.q}`);
        break;
      case TimeSeriesCadenceOptions.Month:
        result = [...dates]
          .map((x) => ({ m: x.substring(5), y: x.substring(0, 4) }))
          .sort((a, b) => sortByProp(a, b, 'm'))
          .sort((a, b) => sortByProp(a, b, 'y'))
          .map((x) => `${x.y}-${x.m}`);
        break;
      case TimeSeriesCadenceOptions.Year:
        result = [...dates]
          .map((x) => +x)
          .sort()
          .map((x) => String(x));
        break;
      default:
        result = [...dates];
    }

    return [...fields, ...result];
  }

  private addProvidedPageParamsOrFallback(page?: number, per_page?: number): { page: number; per_page: number } {
    return {
      page: page ?? 1,
      per_page: per_page ?? 20,
    };
  }

  private toggleBetweenFormulas(
    payload: ReportLayout<any>,
    reportFormula: ReportLayoutSummaryFormula,
  ): Observable<ReportLayout<any>> {
    return this.reportingService
      .update<any>({
        id: payload.id,
        account_id: Number(this.account.id),
        report_layout_summary_formulas: [reportFormula],
      })
      .pipe(
        switchMap(() => {
          payload.report_layout_summary_formulas = payload.report_layout_summary_formulas.filter((x) => !x._destroy);
          return this.reportingService.update<any>(payload);
        }),
      );
  }

  private isParamsForProjectOrCO(params: Graphql.Params): boolean {
    if (params.relatedToType === RelatedToType.Project || !RelatedToType[params.relatedToType]) {
      return true;
    }

    return false;
  }
}
