import { Injectable } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { ColumnDef } from '../ts-table/ts-table.model';
import formatNumber from 'accounting-js/lib/formatNumber.js';
import formatMoney from 'accounting-js/lib/formatMoney.js';
import moment from 'moment';
import { TableActionsRendererComponent } from '../ts-table/renderer-components/table-actions-renderer.component';
import { TsAgMultiSelectRenderer } from '../ts-table/renderer-components/table-select-renderer.component';
import { TsAgMultiSelectEditor } from '../ts-table/renderer-components/table-select-editor.component';
import { TableTagsRendererComponent } from '../ts-table/renderer-components/table-tags-renderer.component';
import { TableAggregationRendererComponent } from '../ts-table/renderer-components/table-aggregation-renderer.component';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class TsTableService {
  convertFieldDefsToColDefs(
    fieldDefs: ColumnDef[],
    relatedToRedirectConfig: any,
    data?: any,
    readOnly: boolean = false,
  ): ColDef[] {
    const fields = fieldDefs.map((fieldDef) => {
      const colDef: ColDef = {
        ...this.setFieldSettings(fieldDef, relatedToRedirectConfig, data, readOnly),
      };
      return colDef;
    });

    return fields;
  }

  setFieldSettings(fieldDef: ColumnDef, relatedToRedirectConfig: any, data?: any, readOnly?: boolean): ColDef {
    const isNestedField = fieldDef.name.includes('.');
    const colDef: ColDef = {
      headerName: isNestedField
        ? fieldDef._parentFieldLabel + ' ' + fieldDef.label
        : fieldDef.label || fieldDef.tsTableOptions?.headerName,
      field: fieldDef.tsTableOptions?.field || fieldDef.name,
      sortable: isNestedField
        ? false
        : fieldDef.tsTableOptions?.sortable
        ? fieldDef.tsTableOptions?.sortable
        : !!fieldDef.meta?.sorting,
      filter: false,
      cellDataType: fieldDef.type,
      editable: (params) => {
        if (readOnly || isNestedField || params.data.isCategory || fieldDef.meta?.readonly) {
          return false;
        }
        if (typeof fieldDef.tsTableOptions?.editable === 'function') {
          return fieldDef.tsTableOptions.editable(params);
        }
        return fieldDef.tsTableOptions?.editable ?? true;
      },
      resizable: fieldDef.tsTableOptions?.resizable,
      checkboxSelection: fieldDef.tsTableOptions?.checkboxSelection,
      headerCheckboxSelection: fieldDef.tsTableOptions?.headerCheckboxSelection,
      suppressMovable: fieldDef.tsTableOptions?.suppressMovable,
      lockPosition: fieldDef.tsTableOptions?.lockPosition,
      lockPinned: fieldDef.tsTableOptions?.lockPinned,
      width: fieldDef.tsTableOptions?.width,
      minWidth: fieldDef.tsTableOptions?.width || 150,
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
      flex: fieldDef.tsTableOptions?.flex,
      suppressAutoSize: fieldDef.tsTableOptions?.suppressAutoSize,
      suppressSizeToFit: fieldDef.tsTableOptions?.suppressSizeToFit,
      autoHeaderHeight: true,
      enableValue: fieldDef.tsTableOptions?.enableValue,
      cellStyle: fieldDef.tsTableOptions?.cellStyle,
      autoHeight: fieldDef.tsTableOptions?.autoHeight,
      pinned: fieldDef.tsTableOptions?.pinned,
      rowGroup: fieldDef.tsTableOptions?.rowGroup,
      hide: fieldDef.tsTableOptions?.rowGroup,
      aggFunc: fieldDef.tsTableOptions?.aggFunc,
      cellClass: fieldDef.tsTableOptions?.cellClass,
      cellClassRules: fieldDef.tsTableOptions?.cellClassRules,
      context: fieldDef,
      sort: fieldDef.tsTableOptions?.sort,
      cellRendererSelector: fieldDef.tsTableOptions?.cellRendererSelector,
      cellEditorSelector: fieldDef.tsTableOptions?.cellEditorSelector,
      valueFormatter: fieldDef.tsTableOptions?.valueFormatter || this.setValueFormatter(fieldDef),
      ...this.setCellEditor(fieldDef, relatedToRedirectConfig, data),
      ...this.setCellRenderer(fieldDef, relatedToRedirectConfig),
    };
    return colDef;
  }

  setValueFormatter(fieldDef: ColumnDef): (params: any) => string {
    switch (fieldDef?.type) {
      case 'date':
      case 'datetime':
        return (params) => {
          const value: any = this.getValueFromParam(fieldDef, params);

          return value ? moment(value).format('MM/DD/YYYY') : '';
        };
      case 'number':
      case 'decimal':
        return (params) => {
          let value: any = this.getValueFromParam(fieldDef, params);

          if (!fieldDef.meta?.scale) {
            return value;
          }

          value = value ? parseFloat(value) : null;

          const formattedValue = value !== null ? formatNumber(value, { precision: fieldDef.meta?.scale || 0 }) : '';

          return formattedValue;
        };
      case 'money':
        return (params) => {
          let value: any = this.getValueFromParam(fieldDef, params);

          value = value !== null && value !== undefined ? parseFloat(value) : null;

          const formattedValue = value !== null ? formatMoney(value, { precision: fieldDef.meta?.scale || 0 }) : '';
          return formattedValue;
        };
      case 'percent':
        return (params) => {
          const value: any = this.getValueFromParam(fieldDef, params);

          const formattedValue = value ? `${formatNumber(value, { precision: fieldDef.meta?.scale || 0 })}%` : '';

          return formattedValue;
        };
      case 'select':
        return (params) => {
          if (fieldDef.name.includes('.')) {
            const nestedValues = this.getLinkedFieldValue(params.data, fieldDef.name);
            if (nestedValues) {
              return nestedValues
                .map((nestedValue) => {
                  const labelOption = this.findOptionById(nestedValue.label, params.colDef.cellEditorParams?.options);
                  return labelOption ? labelOption.name || labelOption.full_name : nestedValue.label;
                })
                .join(', ');
            }
          } else {
            const selectedOption = params.colDef.cellEditorParams?.options?.find((option) => option.id == params.value);
            return selectedOption ? selectedOption.name : params.value;
          }
        };
      default:
        return (params) => null;
    }
  }

  findOptionById(id: string | number, options: any[]): any {
    return options?.find((option) => option.id == id);
  }

  getLinkedFieldValue(object: any, fieldName: string): { label: string; [key: string]: any }[] {
    const firstLevel = fieldName.split('.')[0];
    const propLevel = fieldName.split('.')[1];
    if (Array.isArray(object[firstLevel])) {
      return object[firstLevel].map((item) => ({ label: item[propLevel], ...item }));
    }

    const label = get(object, fieldName);
    if (label) {
      return [{ label: get(object, fieldName), ...object[firstLevel] }];
    }
  }

  setCellEditor(fieldDef: ColumnDef, relatedToRedirectConfig: any, data?: any): Partial<ColDef> {
    switch (fieldDef.type) {
      case 'number':
      case 'decimal':
        return {
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            precision: fieldDef.meta?.scale || 0,
            showStepperButtons: false,
          },
        };
      case 'money':
        return {
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            precision: fieldDef.meta?.scale || 0,
            showStepperButtons: false,
          },
        };
      case 'year':
        return {
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            precision: 0,
            showStepperButtons: false,
            min: 1900,
            max: 9999,
          },
        };
      case 'date':
      case 'datetime':
        return {
          cellEditor: 'agDateStringCellEditor',
          cellDataType: 'dateString',
        };
      case 'checkbox':
        return {
          cellEditor: 'agCheckboxCellEditor',
        };
      case 'textarea':
        return {
          cellEditor: 'agLargeTextCellEditor',
        };
      case 'url':
        return {
          cellEditor: 'agTextCellEditor',
        };
      case 'select':
        let options = fieldDef.meta?.options;
        if (options?.length > 0) {
          options = fieldDef.meta.options.map((value) => ({ id: value, name: value }));
        } else {
          options = data?.options[fieldDef.meta.option_name]
            ?.filter((option) => !option.hidden)
            .map((option) => ({ id: option.id, name: option.name }));
        }

        return {
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: options?.map((option) => option.id) || [],
            options: options,
            allowTyping: true,
            filterList: true,
            multiSelect: fieldDef.meta?.multi_select,
            highlightMatch: true,
            valueListMaxHeight: 220,
          },
        };
      case 'record':
        return {
          cellEditor: TsAgMultiSelectEditor,
          cellRenderer: TsAgMultiSelectRenderer,
          cellEditorParams: {
            multiSelect: fieldDef.meta?.multi_select,
            recordType: fieldDef.meta?.record_type,
            options: fieldDef.meta?.options,
          },
          cellRendererParams: {
            relatedToRedirectConfig,
            recordType: fieldDef.meta?.record_type,
          },
        };
      default:
        return {
          cellEditor: 'agTextCellEditor',
        };
    }
  }

  setCellRenderer(fieldDef: ColumnDef, relatedToRedirectConfig: any): ColDef {
    if (fieldDef.tsTableOptions?.cellRenderer) {
      return {
        cellRenderer: fieldDef.tsTableOptions.cellRenderer,
        cellRendererParams: { ...fieldDef.tsTableOptions.cellRendererParams, relatedToRedirectConfig },
      };
    }

    switch (fieldDef.type) {
      case 'tags':
        return {
          cellRenderer: TableTagsRendererComponent,
          cellEditor: fieldDef.tsTableOptions?.cellEditor,
        };
      case 'checkbox':
        return {
          cellRenderer: 'agCheckboxCellRenderer',
          valueGetter: (params) => {
            return params.data[params.colDef.field] === true || params.data[params.colDef.field] === 'true';
          },
        };
      case 'url':
        return {
          cellRenderer: (params) => {
            const url = params.value;
            return url ? `<a href="${url}" target="_blank" style="text-decoration: underline;">${url}</a>` : '';
          },
        };

      case 'money':
      case 'number':
      case 'decimal':
      case 'percent':
        return {
          cellRendererSelector: (params) => {
            const isCategory = params.data?.isCategory || false;

            if (isCategory) {
              const summaryColumns = params.data.summaryColumns;
              const transformedSummaryColumns = Object.keys(summaryColumns).reduce((acc, key) => {
                const newKey = key.replace(/^group_/, '');
                acc[newKey] = summaryColumns[key];
                return acc;
              }, {});

              const fieldName = params.colDef.field;

              if (fieldName in transformedSummaryColumns) {
                const value = transformedSummaryColumns[fieldName];
                return {
                  component: TableAggregationRendererComponent,
                  params: { value },
                };
              }
            }
          },
          cellRendererParams: { relatedToRedirectConfig },
        };
      default:
        return;
    }
  }

  setActionsColumn(params): ColDef {
    return {
      headerName: 'Actions',
      field: 'actions',
      pinned: 'right',
      lockPinned: true,
      lockPosition: 'right',
      resizable: false,
      suppressMovable: true,
      cellRenderer: TableActionsRendererComponent,
      cellRendererParams: params,
    };
  }

  public static moveColumns(
    event: any,
    displayColumns: string[],
    excludedFields: string[], // cols that we ignore (like 'openDetails', 'checkbox')
    updateColumnsCallback: (columns: string[]) => void, // cb function to update the columns
  ): void {
    if (event.toIndex !== undefined && event.finished) {
      const movedColumn = event.column?.colDef?.field;

      if (movedColumn && !excludedFields.includes(movedColumn)) {
        const previousIndex = displayColumns.indexOf(movedColumn);
        const currentIndex = event.toIndex - excludedFields.length; // index adjustment based on the columns that we're going to ignore

        if (previousIndex !== currentIndex && previousIndex > -1) {
          moveItemInArray(displayColumns, previousIndex, currentIndex);
          updateColumnsCallback(displayColumns);
        }
      }
    }
  }

  private getValueFromParam(columnDef: ColumnDef, params: any): any {
    let value: any;

    if (columnDef.name.indexOf('.') > -1) {
      const fieldName = columnDef.name.split('.').pop();
      value = params.value?.[fieldName];
    } else {
      value = params.value;
    }

    return value;
  }

  setCellRendererSelector(fieldDef, relatedToRedirectConfig?: any, data?: any): any {
    switch (fieldDef?.type) {
      case 'tags':
        return { component: TableTagsRendererComponent };

      case 'checkbox':
        return { component: 'agCheckboxCellRenderer' };

      case 'url':
        return {
          component: 'agTextCellRenderer',
          params: {
            renderer: (params) => {
              const url = params.value;
              return url ? `<a href="${url}" target="_blank" style="text-decoration: underline;">${url}</a>` : '';
            },
          },
        };

      case 'number':
        return {
          component: 'agTextCellRenderer',
          params: {
            renderer: (params) => {
              let value: any = this.getValueFromParam(fieldDef, params);
              if (!fieldDef.meta?.scale) return value;
              value = value ? parseFloat(value) : null;
              return value !== null ? formatNumber(value, { precision: fieldDef.meta?.scale || 0 }) : '';
            },
          },
        };

      case 'money':
        return {
          component: 'agTextCellRenderer',
          params: {
            renderer: (params) => {
              let value: any = this.getValueFromParam(fieldDef, params);
              value = value !== null && value !== undefined ? parseFloat(value) : null;
              return value !== null ? formatMoney(value) : '';
            },
          },
        };

      case 'percent':
        return {
          component: 'agTextCellRenderer',
          params: {
            renderer: (params) => {
              const value: any = this.getValueFromParam(fieldDef, params);
              return value ? `${value}%` : '';
            },
          },
        };

      case 'select':
        return {
          component: 'agTextCellRenderer',
          params: {
            renderer: (params) => {
              let options = fieldDef.meta?.options;
              if (options?.length > 0) {
                options = fieldDef.meta.options.map((value) => ({ id: value, name: value }));
              } else {
                options = data?.options[fieldDef.meta.option_name]
                  ?.filter((option) => !option.hidden)
                  .map((option) => ({ id: option.id, name: option.name }));
              }
              if (fieldDef.name.includes('.')) {
                const nestedValues = this.getLinkedFieldValue(params.data, fieldDef.name);
                if (nestedValues) {
                  return nestedValues
                    .map((nestedValue) => {
                      const labelOption = this.findOptionById(nestedValue.label, options);
                      return labelOption ? labelOption.name || labelOption.full_name : nestedValue.label;
                    })
                    .join(', ');
                }
              } else {
                const selectedOption = options?.find((option) => option.id == params.value);
                return selectedOption ? selectedOption.name : params.value;
              }
            },
          },
        };

      case 'record':
        return {
          component: TsAgMultiSelectRenderer,
          params: {
            recordType: fieldDef.meta?.record_type,
            relatedToRedirectConfig: relatedToRedirectConfig,
          },
        };

      case 'text':
        return {
          component: 'agTextCellRenderer',
        };

      default:
        return null;
    }
  }

  setCellEditorSelector(fieldDef, relatedToRedirectConfig: any, data?: any): any {
    switch (fieldDef?.type) {
      case 'number':
        return {
          component: 'agNumberCellEditor',
          params: {
            precision: fieldDef.meta?.scale || 0,
            showStepperButtons: false,
          },
        };
      case 'money':
        return {
          component: 'agNumberCellEditor',
          params: {
            precision: fieldDef.meta?.scale || 0,
            showStepperButtons: false,
          },
        };
      case 'year':
        return {
          component: 'agNumberCellEditor',
          params: {
            precision: 0,
            showStepperButtons: false,
            min: 1900,
            max: 9999,
          },
        };
      case 'date':
      case 'datetime':
        return {
          component: 'agDateStringCellEditor',
          params: {
            cellDataType: 'dateString',
          },
        };
      case 'checkbox':
        return {
          component: 'agCheckboxCellEditor',
        };
      case 'textarea':
        return {
          component: 'agLargeTextCellEditor',
        };
      case 'url':
        return {
          component: 'agTextCellEditor',
        };
      case 'select':
        let options = fieldDef.meta?.options;
        if (options?.length > 0) {
          options = fieldDef.meta.options.map((value) => ({ id: value, name: value }));
        } else {
          options = data?.options[fieldDef.meta.option_name]
            ?.filter((option) => !option.hidden)
            .map((option) => ({ id: option.id, name: option.name }));
        }

        return {
          component: 'agRichSelectCellEditor',
          params: {
            values: options?.map((option) => option.id) || [],
            options: options,
            allowTyping: true,
            filterList: true,
            multiSelect: fieldDef.meta?.multi_select,
            highlightMatch: true,
            valueListMaxHeight: 220,
          },
        };
      case 'record':
        return {
          component: TsAgMultiSelectEditor,
          params: {
            multiSelect: fieldDef.meta?.multi_select,
            recordType: fieldDef.meta?.record_type,
            options: fieldDef.meta?.options,
          },
        };
      default:
        return {
          component: 'agTextCellEditor',
        };
    }
  }

  setValueFormatterSelector(fieldDef, relatedToRedirectConfig?: any, data?: any): (params: any) => string {
    return (params) => {
      const value = params.value;
      if (value === undefined || value === null) {
        return '';
      }

      switch (fieldDef?.type) {
        case 'date':
        case 'datetime':
          return moment(value).format('MM/DD/YYYY');

        case 'select':
          let options = fieldDef.meta?.options;
          if (options?.length > 0) {
            options = fieldDef.meta.options.map((value) => ({ id: value, name: value }));
          } else {
            options = data?.options[fieldDef.meta.option_name]
              ?.filter((option) => !option.hidden)
              .map((option) => ({ id: option.id, name: option.name }));
          }
          const selectedOption = options?.find((option) => option.id == value);
          return selectedOption ? selectedOption.name : value;

        case 'number':
          if (!fieldDef.meta?.scale) return value;
          const parsedValue = parseFloat(value);
          return !isNaN(parsedValue) ? formatNumber(parsedValue, { precision: fieldDef.meta?.scale || 0 }) : '';

        case 'money':
          const moneyValue = parseFloat(value);
          return !isNaN(moneyValue) ? formatMoney(moneyValue) : '';

        case 'percent':
          return `${value}%`;

        default:
          return value;
      }
    };
  }
}
