import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { TsEmptyStateComponent } from 'tsui/lib/ts-empty-state/ts-empty-state.component';
import { RollbarErrorHandler } from '@core/services';
import { Store } from '@ngxs/store';
import { UserState } from '@state/user/state';
import { AccountState } from '@state/account/state';

@Component({
  selector: 'not-found',
  standalone: true,
  imports: [TsEmptyStateComponent],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss',
  providers: [RollbarErrorHandler],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent implements OnInit {
  readonly store = inject(Store);
  readonly rollbar = inject(RollbarErrorHandler);
  readonly goBackButtonConfig: { label: string; actionFn: () => void } = {
    label: 'Go back',
    actionFn: () => {
      window.history.back();
    },
  };

  get userId(): number {
    return this.store.selectSnapshot(UserState.getUser)?.id;
  }

  get accountSubdomain(): string {
    return this.store.selectSnapshot(AccountState.getAccount)?.subdomain;
  }

  ngOnInit() {
    this.rollbar.sendWarning(
      `Warning: User ${this.userId} attempted to access a non-existent route on ${this.accountSubdomain}`,
    );
  }
}
