import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  private ignorePath = ['/integrations/oauth_connect/authenticate', '/integrations/saml/authenticate'];

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const ignoreApiPrefix = this.ignorePath.filter((path) => request.url.includes(path)).length > 0;
    const basePath = request.url.split('?')[0];

    if (
      !ignoreApiPrefix &&
      !basePath.includes('/api/') &&
      !basePath.includes('assets') &&
      !basePath.includes('version.json')
    ) {
      const apiReq = request.clone({ url: `${environment.baseUrl}/${environment.apiVersion}/${request.url}` });
      return next.handle(apiReq);
    } else {
      let newReq = request.clone();
      return next.handle(newReq);
    }
  }
}
