import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, EMPTY, forkJoin, Observable, of, Subject, throwError, zip } from 'rxjs';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import clone from 'lodash-es/clone';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { FieldDef, UserAssociation } from 'app/core/models';
import { FileFormat, RelatedToType } from 'app/shared/enums';
import { Document } from 'app/shared/modules/folders/folders.types';
import { Contact, DealAssociatedContacts } from 'app/modules/contacts/contacts.types';
import { PartialFormField } from 'app/shared/components/form-fields/form-field.types';
import {
  Appointment,
  Comment,
  Image,
  KanbanFilter,
  Pipeline,
  Project,
  ProjectPagination,
  ProjectTemplate,
  Property,
  SaveProjectMode,
} from './projects.types';
import GraphqlService from 'app/services/graphql.service';
import {
  GraphqlConnection,
  GraphqlConnectionMap,
  GraphqlFilter,
  GraphqlParams,
  GraphqlResultInfo,
} from 'app/shared/models/graphql';
import { Dictionary } from 'app/shared/models';
import { SortDirection } from '@angular/material/sort';
import { TsRxjsOperators } from '@shared/Utils/custom-rxjs-operators.utils';
import { ExportOptions } from '@shared/models/export-options';

const pipelinesCacheBuster$ = new Subject<void>();
const projectCacheBuster$ = new Subject<void>();
const projectTemplatesCacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  _pipeline: BehaviorSubject<Pipeline | null>;
  private _pipelines: BehaviorSubject<Pipeline[] | null>;
  private _functions: BehaviorSubject<Pipeline[] | null>;
  private _projects: BehaviorSubject<Project[] | null>;
  private _changeProjectState: BehaviorSubject<boolean | false>;
  private _project: BehaviorSubject<Project | null>;
  private _pagination: BehaviorSubject<ProjectPagination | null>;
  private _appointments: BehaviorSubject<Appointment[] | null>;
  private _comments: BehaviorSubject<Comment[] | null>;
  private _contacts: BehaviorSubject<Contact[] | null>;
  private _changePanelState: BehaviorSubject<boolean | false>;
  private _exportPdf: BehaviorSubject<string>;
  private _userAssociations: BehaviorSubject<UserAssociation[] | null>;
  private _geoJSON: BehaviorSubject<any[] | null>;
  private _projectTemplates: BehaviorSubject<ProjectTemplate<any>[] | null>;
  _projectTemplate: BehaviorSubject<ProjectTemplate<any> | null>;
  private _panelDefinition: BehaviorSubject<any | null>;
  private _accountId: string;
  private _loading = new BehaviorSubject<boolean>(false);
  public loading$ = this._loading.asObservable();

  constructor(private _httpClient: HttpClient, private readonly graphqlService: GraphqlService) {
    this._pipeline = new BehaviorSubject(null);
    this._pipelines = new BehaviorSubject(null);
    this._functions = new BehaviorSubject(null);
    this._appointments = new BehaviorSubject(null);
    this._comments = new BehaviorSubject(null);
    this._contacts = new BehaviorSubject(null);
    this._changePanelState = new BehaviorSubject(false);
    this._exportPdf = new BehaviorSubject(null);
    this._changeProjectState = new BehaviorSubject(false);
    this._userAssociations = new BehaviorSubject(null);
    this._panelDefinition = new BehaviorSubject(null);

    this._projects = new BehaviorSubject(null);
    this._project = new BehaviorSubject(null);
    this._pagination = new BehaviorSubject(null);
    this._geoJSON = new BehaviorSubject(null);
    this._projectTemplates = new BehaviorSubject(null);
    this._projectTemplate = new BehaviorSubject(null);
  }

  get pipeline$(): Observable<Pipeline> {
    return this._pipeline.asObservable();
  }

  get pipelines$(): Observable<Pipeline[]> {
    return this._pipelines.asObservable();
  }

  get functions$(): Observable<any[]> {
    return this._functions.asObservable();
  }

  get projects$(): Observable<Project[]> {
    return this._projects.asObservable();
  }

  get project$(): Observable<Project> {
    return this._project.asObservable();
  }

  get pagination$(): Observable<ProjectPagination> {
    return this._pagination.asObservable();
  }

  get appointments$(): Observable<Appointment[]> {
    return this._appointments.asObservable();
  }

  get contacts$(): Observable<Contact[]> {
    return this._contacts.asObservable();
  }

  get userAssociations$(): Observable<UserAssociation[]> {
    return this._userAssociations.asObservable();
  }

  get changePanelState$(): Observable<boolean> {
    return this._changePanelState.asObservable();
  }

  get exportPdfState$(): Observable<string> {
    return this._exportPdf.asObservable();
  }

  get changeProjectState$(): Observable<boolean> {
    return this._changeProjectState.asObservable();
  }

  get panelDefinition$(): Observable<any> {
    return this._panelDefinition.asObservable();
  }

  get comments$(): Observable<Comment[]> {
    return this._comments.asObservable();
  }

  get geoJSON$(): Observable<Comment[]> {
    return this._comments.asObservable();
  }

  get projectTemplates$(): Observable<ProjectTemplate<any>[]> {
    return this._projectTemplates.asObservable();
  }

  get projectTemplate$(): Observable<ProjectTemplate<any>> {
    return this._projectTemplate.asObservable();
  }

  set accountId(val: string) {
    this._accountId = val;
  }

  get currentProject(): Project {
    return this._project.value;
  }

  bustPipelineCache(): void {
    pipelinesCacheBuster$.next();
  }

  bustProjectTemplatesCache(): void {
    projectTemplatesCacheBuster$.next();
  }

  @Cacheable({
    cacheBusterObserver: pipelinesCacheBuster$,
  })
  getPipelines(includePrivate: boolean = false): Observable<Pipeline[]> {
    if (includePrivate) {
      return this._httpClient.get<any>(`pipelines?account_id=${this._accountId}&include_private=true`).pipe(
        map((response) => {
          return response.pipelines;
        }),
        TsRxjsOperators.handle403toEmptyArray(),
      );
    } else {
      return this._httpClient.get<any>(`pipelines?account_id=${this._accountId}`).pipe(
        map((response) => {
          this._pipelines.next(response.pipelines);
          return response.pipelines;
        }),
        TsRxjsOperators.handle403toEmptyArray(),
      );
    }
  }

  getPipelinesWithoutCache(includePrivate = false): Observable<Pipeline[]> {
    if (includePrivate) {
      return this._httpClient
        .get<{ pipelines: Pipeline[] }>(`pipelines?account_id=${this._accountId}&include_private=true`)
        .pipe(
          map((response) => {
            return response.pipelines;
          }),
          TsRxjsOperators.handle403toEmptyArray(),
        );
    } else {
      return this._httpClient.get<{ pipelines: Pipeline[] }>(`pipelines?account_id=${this._accountId}`).pipe(
        map((response) => {
          this._pipelines.next(response.pipelines);
          return response.pipelines;
        }),
        TsRxjsOperators.handle403toEmptyArray(),
      );
    }
  }

  getFunctions(): Observable<any[]> {
    return this._httpClient.get<any>(`custom_fields/functions`).pipe(
      map((response) => {
        this._functions.next(response.functions);
        return response.functions;
      }),
    );
  }

  @Cacheable({
    cacheBusterObserver: pipelinesCacheBuster$,
  })
  getPipelineById(id: string): Observable<Pipeline> {
    return this._httpClient.get<any>(`pipelines/${id}?account_id=${this._accountId}`).pipe(
      map((response) => {
        if (!response) return EMPTY;
        this._pipeline.next(response.pipeline);
        return response.pipeline;
      }),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: pipelinesCacheBuster$,
  })
  createPipeline(pipeline: Pipeline): Observable<Pipeline> {
    return this.pipelines$.pipe(
      take(1),
      switchMap((pipelines) =>
        this._httpClient.post<any>(`pipelines?account_id=${this._accountId}`, { pipeline: pipeline }).pipe(
          map((reponse) => {
            // Update pipelines with the new pipeline
            this._pipelines.next([reponse.pipeline, ...pipelines]);

            // Return the new pipeline
            return reponse.pipeline;
          }),
        ),
      ),
    );
  }

  /**
   * @param pipeline typeof Pipeline | Pipeline object to be updated.
   * @param getUpdatedPipelines typeof boolean | Flag used to trigger or not the getPipelines() method. Defaults to true.
   */
  @CacheBuster({
    cacheBusterNotifier: pipelinesCacheBuster$,
  })
  updatePipeline(pipeline: Pipeline, getUpdatedPipelines: boolean = true): Observable<Pipeline> {
    return this.pipelines$.pipe(
      take(1),
      switchMap((pipelines) =>
        this._httpClient
          .put<any>(`pipelines/${pipeline.id}?account_id=${this._accountId}`, { pipeline: pipeline })
          .pipe(
            map((response) => {
              let updatedPipeline = response.pipeline;

              // Find the index of the updated pipeline
              const index = pipelines.findIndex((item) => item.id === updatedPipeline.id);

              // Update the pipeline
              pipelines[index] = updatedPipeline;

              // Update pipeline
              this._pipeline.next(updatedPipeline);

              // Return the updated pipeline
              return updatedPipeline;
            }),
          ),
      ),
      finalize(() => {
        if (getUpdatedPipelines) this.getPipelines().subscribe();
      }),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: pipelinesCacheBuster$,
  })
  deletePipeline(id: number): Observable<any> {
    return this.pipelines$.pipe(
      take(1),
      switchMap((pipelines) =>
        this._httpClient.delete<any>(`pipelines/${id}?account_id=${this._accountId}`).pipe(
          map((reponse) => {
            // Find the index of the deleted label within the labels
            const index = pipelines.findIndex((item) => item.id === id);

            // Delete the label
            pipelines.splice(index, 1);

            // Update the labels
            this._pipelines.next(pipelines);

            // Return the deleted status
            return reponse.pipeline;
          }),
        ),
      ),
    );
  }

  duplicateProject(id: number) {
    return this._httpClient.post<any>(`projects/${id}/clone?account_id=${this._accountId}`, {}).pipe(
      map((reponse) => {
        return reponse.project;
      }),
    );
  }

  archiveProject(id: number) {
    return this._httpClient.put<any>(`projects/${id}/archive?account_id=${this._accountId}`, {}).pipe(
      map((reponse) => {
        return reponse.project;
      }),
    );
  }
  unarchiveProject(id: number) {
    return this._httpClient.put<any>(`projects/${id}/unarchive?account_id=${this._accountId}`, {}).pipe(
      map((reponse) => {
        return reponse.project;
      }),
    );
  }

  private _massageRequestFields(fields: string[]) {
    let requestFields = {};

    this.includeMissingFields(fields, requestFields);
    this.handleComplexTypes(fields, requestFields);

    return requestFields;
  }

  private includeMissingFields(fields: string[], requestFields: any): void {
    for (let field of fields) {
      requestFields[field] = true;
    }
    if (!fields.includes('id')) {
      requestFields['id'] = true;
    }
    if (!fields.includes('address_string')) {
      requestFields['address_string'] = true;
    }
    if (!fields.includes('featured_image_url')) {
      requestFields['featured_image_url'] = true;
    }
    if (!fields.includes('title')) {
      requestFields['title'] = true;
    }
    if (!fields.includes('address_string')) {
      requestFields['address_string'] = true;
    }
    if (!fields.includes('project_stage_id')) {
      requestFields['project_stage_id'] = true;
    }
    if (!fields.includes('state')) {
      requestFields['state'] = true;
    }
  }

  private handleComplexTypes(fields: string[], requestFields: any): void {
    if (fields.includes('address')) {
      requestFields['address'] = {
        latitude: true,
        longitude: true,
      };
    }

    if (fields.includes('children')) {
      requestFields['children'] = {};
      for (let field of fields) {
        if (field !== 'children') requestFields['children'][field] = true;
      }
      requestFields['children']['id'] = true;
      requestFields['children']['is_child'] = true;
      requestFields['children']['project_stage_id'] = true;
    }

    if (fields.includes('properties')) {
      requestFields['properties'] = {};
      requestFields['properties']['id'] = true;
      requestFields['properties']['title'] = true;
      requestFields['properties']['latitude'] = true;
      requestFields['properties']['longitude'] = true;
      requestFields['properties']['address_string'] = true;
    }

    requestFields['project_stage'] = {
      id: true,
      name: true,
      color: true,
    };
    requestFields['market_status'] = {
      id: true,
      name: true,
    };
    requestFields['investment_type'] = {
      id: true,
      name: true,
    };
    requestFields['project_type'] = {
      id: true,
      name: true,
    };
    requestFields['property_type'] = {
      id: true,
      name: true,
      color: true,
    };
    requestFields['vehicle'] = {
      id: true,
      name: true,
    };
    requestFields['team'] = {
      id: true,
      name: true,
    };
    requestFields['assigned_user'] = {
      id: true,
      name: true,
    };
    requestFields['project_phase'] = {
      id: true,
      name: true,
    };
    requestFields['is_child'] = true;
    requestFields['is_parent'] = true;
    requestFields['pipeline_id'] = true;
    requestFields['root_folder_resource_id'] = true;

    requestFields['permissions'] = {
      is_deletable: true,
      is_viewable: true,
      is_editable: true,
    };

    return requestFields;
  }

  getProjectExists(title: string, pipelineId: number): Observable<any> {
    return this._httpClient
      .get<any>(`projects/by_name?name=${title}&pipeline_id=${pipelineId}&account_id=${this._accountId}`)
      .pipe(
        map((response) => response),
        catchError(() => of(false)),
      );
  }

  getProjects(
    fields: string[] = [],
    queryFilters: any = {},
    page: number = 0,
    size: number = 20,
    sort: any = 'project_stage_rank',
    order: 'asc' | 'desc' | '' = 'asc',
    search: string = '',
    format: FileFormat = null,
    group: string = '',
    isChildren: boolean = false,
    updatedProjects: boolean = true,
    addChildren: boolean = false,
    nestedOptions: { fieldDefs: FieldDef[] } = null,
  ): Observable<any> {
    this._loading.next(true);

    const resultFunc = (response: any) => {
      if ([FileFormat.CSV, FileFormat.PDF].includes(format as FileFormat)) {
        return response.data;
      }
      const pageInfo = response.data.projects.pageInfo;
      const lastPage = Math.max(Math.ceil(pageInfo.filteredCount / size), 1);
      const begin = page * size;
      const end = Math.min(size * (page + 1), pageInfo.filteredCount);
      const pagination = {
        length: pageInfo.filteredCount,
        size: size,
        page: page,
        lastPage: lastPage,
        startIndex: begin,
        endIndex: end,
      };
      const projects = response.data.projects.edges.map((edge) => edge.node);
      if (!isChildren) this._pagination.next(pagination);
      // eslint-disable-next-line max-len
      // If user toggled 'include children' in filters panel, we push graphQl search results directly and update the new list pagination.
      if (isChildren && addChildren) {
        this._pagination.next(pagination);
        this._projects.next(projects);
      } else if (isChildren && !addChildren) {
        this._projects.next([...this._projects.value, ...projects]);
      } else if (updatedProjects) {
        this._projects.next(projects);
      }
      return {
        pagination,
        projects,
      };
    };

    if (nestedOptions) {
      const enrichedFields = [...fields, 'template_id'];

      if (!fields.includes('id')) {
        enrichedFields.push('id');
      }
      if (!fields.includes('address_string')) {
        enrichedFields.push('address_string');
      }
      if (!fields.includes('featured_image_url')) {
        enrichedFields.push('featured_image_url');
      }
      if (!fields.includes('title')) {
        enrichedFields.push('title');
      }
      if (!fields.includes('address_string')) {
        enrichedFields.push('address_string');
      }
      if (!fields.includes('project_stage_id')) {
        enrichedFields.push('project_stage_id');
      }
      if (!fields.includes('state')) {
        enrichedFields.push('state');
      }

      let exportOptions: ExportOptions = null;
      if (format) {
        exportOptions = { format };
      }

      let filter: GraphqlFilter = {
        fjson: queryFilters.fjson,
        sort: Array.isArray(sort) ? sort : [{ order, field: sort }],
        query: search || queryFilters['q'] || '',
        pagination: { page: page + 1, size },
        queryObject: {
          group,
          ...(addChildren ? { force_include_child: true } : {}),
        },
      };

      if (this._pipeline.value.pipeline_type !== 'dynamic') {
        filter = {
          ...filter,
          pipeline_id: this._pipeline.value.id,
          queryObject: { ...filter.queryObject, pipeline_id: this._pipeline.value.id },
        };
      }

      return this.graphqlService
        .query<GraphqlFilter>(
          Number(this._accountId),
          {
            connection: GraphqlConnection.projectsSqlBased,
            fields: enrichedFields,
            fieldDefs: nestedOptions.fieldDefs,
            filter,
            options: {
              keepConnectionCase: true,
            },
            normalizeMethod: (fields: string[]): any => {
              const requestFields = {};
              this.handleComplexTypes(fields, requestFields);
              return requestFields;
            },
          },
          exportOptions,
        )
        .pipe(
          switchMap((response: any) => (exportOptions ? of({ data: response }) : of({ data: { projects: response } }))),
          map(resultFunc),
          finalize(() => {
            this._loading.next(false);
          }),
        );
    }

    let node = this._massageRequestFields(clone([...fields, 'template_id']));

    const filter = clone(queryFilters);
    for (const key in filter) {
      if (filter[key] && filter[key].length > 0) {
        if (Array.isArray(filter[key])) {
          filter[key] = filter[key].join(',');
        }
      } else if (filter[key] && filter[key].length === 0) {
        if (Array.isArray(filter[key])) {
          filter[key] = '';
        }
      }
    }
    filter['q'] = search || queryFilters['q'] || '';

    const args = {
      filter,
      page: page + 1,
      perPage: size,
    };
    if (Array.isArray(sort)) {
      args['msort'] = sort;
    } else {
      args['sort'] = { field: sort, order: order };
    }

    return this.pipeline$.pipe(
      take(1),
      switchMap((pipeline) => {
        if (pipeline.pipeline_type != 'dynamic') {
          filter['pipelineIdEq'] = pipeline.id;
        }
        const query = {
          query: {
            projects: {
              __args: args,
              pageInfo: {
                hasNextPage: true,
                hasPreviousPage: true,
                endCursor: true,
                startCursor: true,
                totalCount: true,
                filteredCount: true,
              },
              edges: {
                cursor: true,
                node,
              },
            },
          },
        };
        const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
        const url = `graphql?account_id=${this._accountId}&pipeline_id=${pipeline.id}&format=${format}&group=${group}${
          addChildren ? '&force_include_child=true' : ''
        }`;

        return this._httpClient
          .post<{ pagination: ProjectPagination; projects: Project[] }>(url, {
            query: gqlQuery,
          })
          .pipe(
            map(resultFunc),
            finalize(() => {
              this._loading.next(false);
            }),
          );
      }),
    );
  }

  /**
   * Get contacts
   *
   * @param sortField
   * @param sortDirection
   */
  getProjectsNoPipeline(
    fields: string[] = [],
    queryFilters: any = {},
    page: number = 0,
    size: number = 20,
    sort = 'project_stage_rank',
    order: 'asc' | 'desc' | '' = 'desc',
    search: string = '',
    skipNext = false,
    format: FileFormat = null,
    includeChildren = false,
  ): Observable<{ pagination: ProjectPagination; projects: Project[] } | any> {
    this._loading.next(true);
    let node = this._massageRequestFields(clone(fields));

    const filter = clone(queryFilters);
    for (const key in filter) {
      if (filter[key] && filter[key].length > 0) {
        if (Array.isArray(filter[key])) {
          filter[key] = filter[key].join(',');
        }
      } else if (filter[key] && filter[key].length === 0) {
        if (Array.isArray(filter[key])) {
          filter[key] = '';
        }
      }
    }
    if (search) {
      filter['q'] = search;
    } else {
      filter['q'] = '';
    }

    const args = {
      filter,
      page: page + 1,
      perPage: size,
    };
    if (Array.isArray(sort)) {
      args['msort'] = sort;
    } else {
      args['sort'] = { field: sort, order: order };
    }

    const query = {
      query: {
        projects: {
          __args: args,
          pageInfo: {
            hasNextPage: true,
            hasPreviousPage: true,
            endCursor: true,
            startCursor: true,
            totalCount: true,
            filteredCount: true,
          },
          edges: {
            cursor: true,
            node,
          },
        },
      },
    };
    const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

    let url = `graphql?account_id=${this._accountId}`;
    if (includeChildren) {
      url += '&force_include_child=true';
    }

    if (format === FileFormat.CSV) {
      url += `&format=${format}`;
    }

    return this._httpClient
      .post<{ pagination: ProjectPagination; projects: Project[] }>(url, {
        query: gqlQuery,
      })
      .pipe(
        map((response: any) => {
          if ([FileFormat.CSV, FileFormat.PDF].includes(format)) {
            return response.data;
          }

          const pageInfo = response.data.projects.pageInfo;
          const lastPage = Math.max(Math.ceil(pageInfo.filteredCount / size), 1);
          const begin = page * size;
          const end = Math.min(size * (page + 1), pageInfo.filteredCount);
          const pagination = {
            length: pageInfo.filteredCount,
            size: size,
            page: page,
            lastPage: lastPage,
            startIndex: begin,
            endIndex: end,
          };
          const projects = response.data.projects.edges.map((edge) => edge.node);

          if (!skipNext) {
            this._pagination.next(pagination);
            this._projects.next(projects);
          }
          return {
            pagination,
            projects,
          };
        }),
        finalize(() => this._loading.next(false)),
      );
  }

  exploreProjects(
    fields: string[] = [],
    queryFilters: any = {},
    page: number = 0,
    size: number = 20,
    sort = 'projects.created_at',
    order: SortDirection = 'desc',
    search: string = '',
  ): Observable<{ pagination: ProjectPagination; projects: Project[] }> {
    this._loading.next(true);
    let node = this._massageRequestFields(clone(fields));

    const filter = clone(queryFilters);
    for (const key in filter) {
      if (filter[key] && filter[key].length > 0) {
        if (Array.isArray(filter[key])) {
          filter[key] = filter[key].join(',');
        }
      }
    }

    filter['q'] = search || queryFilters['q'] || '*';

    const query = {
      query: {
        projects: {
          __args: {
            filter,
            page: page + 1,
            perPage: size,
            sort: { field: sort, order: order },
          },
          pageInfo: {
            hasNextPage: true,
            hasPreviousPage: true,
            endCursor: true,
            startCursor: true,
            totalCount: true,
            filteredCount: true,
          },
          edges: {
            cursor: true,
            node,
          },
        },
      },
    };
    const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

    return this._httpClient
      .post<{ pagination: ProjectPagination; projects: Project[] }>(`graphql?account_id=${this._accountId}`, {
        query: gqlQuery,
      })
      .pipe(
        map((response: any) => {
          const pageInfo = response.data.projects.pageInfo;

          const lastPage = Math.max(Math.ceil(pageInfo.filteredCount / size), 1);
          const begin = page * size;
          const end = Math.min(size * (page + 1), pageInfo.filteredCount);

          const pagination = {
            length: pageInfo.filteredCount,
            size: size,
            page: page,
            lastPage: lastPage,
            startIndex: begin,
            endIndex: end,
          };
          const projects = response.data.projects.edges.map((edge) => edge.node);
          this._pagination.next(pagination);
          this._projects.next(projects);
          return {
            pagination,
            projects,
          };
        }),
        finalize(() => this._loading.next(false)),
      );
  }

  saveProjects(projectsToUpdate: Project[], mode: SaveProjectMode = null): Observable<Project[]> {
    return zip(
      forkJoin(
        projectsToUpdate.map((project) =>
          this._httpClient
            .put<{ project: Project }>(`projects/${project.id}?account_id=${this._accountId}`, { project })
            .pipe(map((response) => response.project)),
        ),
      ),
      of(this._projects.value),
    ).pipe(
      tap(([updatedProjects, projects]) => {
        switch (mode) {
          case SaveProjectMode.MovePipeline:
            projects = projects.filter(({ id }) => updatedProjects.findIndex((item) => item.id == id) < 0);
            break;
          case SaveProjectMode.ChangeStatus:
            break;
          default:
            if (projects) {
              for (const updatedProject of updatedProjects) {
                const index = projects.findIndex((item) => item.id == updatedProject.id);
                projects[index] = updatedProject;
              }

              this._projects.next(projects);
            }
            break;
        }
      }),
      map(([_, projects]) => projects),
    );
  }

  updateProject(project: PartialFormField, rollup: boolean = false): Observable<any> {
    return this._httpClient.put<any>(`projects/${project.id}?account_id=${this._accountId}&rollup=${rollup}`, {
      project,
    });
  }

  @CacheBuster({
    cacheBusterNotifier: projectCacheBuster$,
  })
  saveProject(
    project: Project | PartialFormField,
    fullProject: Project | PartialFormField = {},
    pushNextProject = true,
    updateAtIndex = true,
  ): Observable<Project> {
    // Service only accepts tag_list values as an array of strings and sometimes it can be a string of values splitted by commas.
    // We do the operation below to equalize the values.
    if (project['tag_list'] && typeof project['tag_list'] === 'string') {
      project['tag_list'] = project['tag_list'].split(',');
    }

    if (project.id) {
      const params: any = {
        project,
      };

      return this.projects$.pipe(
        take(1),
        switchMap((projects) =>
          this._httpClient.put<any>(`projects/${project.id}?account_id=${this._accountId}`, params).pipe(
            map((response) => {
              let updatedProject = response.project;
              //[sc-10190] - allow for override on currentObject. This is mainly to fix updating from the pipeline view
              if (!pushNextProject) {
                updatedProject = { ...fullProject, ...project, ...response.project };
              } else {
                updatedProject = { ...(this.currentProject || fullProject), ...project, ...response.project };
              }

              // Find the index of the updated contact
              if (projects) {
                let index = projects.findIndex((item) => item.id === project.id);

                if (index < 0) index = projects.findIndex((item) => item.id === project.id.toString());

                if (index < 0) index = projects.findIndex((item) => item.id.toString() === project.id);

                if (!updatedProject?.project_stage) {
                  updatedProject.project_stage = projects[index]?.project_stage;
                }

                if (!updatedProject?.template) {
                  updatedProject.template = projects[index]?.template;
                }

                // Update the contact
                if (updateAtIndex) {
                  projects[index] = updatedProject;
                }
                // Update the contacts
                this._projects.next(projects);
              }

              // Return the updated contact
              return updatedProject;
            }),
            switchMap((updatedProject) =>
              this.project$.pipe(
                take(1),
                map(() => {
                  if (pushNextProject) {
                    // Update the contact if it's selected
                    this._project.next(updatedProject);
                  }
                  // Return the updated contact
                  return updatedProject;
                }),
              ),
            ),
          ),
        ),
      );
    } else {
      const params: any = {
        project,
      };

      return this.projects$.pipe(
        take(1),
        switchMap((projects) =>
          this._httpClient.post<any>(`projects?account_id=${this._accountId}`, params).pipe(
            map((reponse) => {
              if (projects) {
                // Update the contacts with the new contact
                this._projects.next([reponse.project, ...projects]);
              }
              if (pushNextProject) {
                //update so that we can add properties
                this._project.next(reponse.project);
              }

              // Return the new contact
              return reponse.project;
            }),
          ),
        ),
        finalize(() => this.projectChange(true)),
      );
    }
  }

  /**
   * Blow the project cache
   */
  bustProjectCache(): void {
    projectCacheBuster$.next();
  }

  exportTabToPdf(id: string, tabId: string) {
    return this._httpClient.get<any>(`projects/${id}/export_tab_pdf?account_id=${this._accountId}&tab_id=${tabId}`);
  }

  /**
   * Get project by id
   */
  @Cacheable({
    cacheBusterObserver: projectCacheBuster$,
  })
  getProjectById(
    id: string,
    format: string = 'json',
    touch: boolean = false,
    accountId: string = null,
  ): Observable<any> {
    accountId = accountId ?? this._accountId;

    return this._httpClient.get<any>(`projects/${id}?account_id=${accountId}&format=${format}&touch=${touch}`).pipe(
      map((response) => {
        if (format == 'pdf' || format == 'docx' || format == 'csv') {
          return response.data;
        } else {
          this._project.next(response.project);
          return response.project;
        }
      }),
    );
  }

  getProjectByIdBase(id: string): Observable<Project> {
    return this._httpClient
      .get<{ project: Project }>(`projects/${id}?account_id=${this._accountId}&format=json`)
      .pipe(map((response) => response.project));
  }

  generateProjectDocument(
    id: string,
    format: string = 'json',
    file_resource_id: number = null,
    sendToDocusign: boolean = null,
  ): Observable<any> {
    return this._httpClient
      .get<any>(
        `projects/${id}?account_id=${this._accountId}&format=${format}&file_resource_id=${file_resource_id}&send_to_docusign=${sendToDocusign}`,
      )
      .pipe(
        map((response) => {
          if (format == 'pdf' || format == 'docx' || format == 'pptx') {
            return response;
          } else {
            this._project.next(response.project);
            return response.project;
          }
        }),
      );
  }

  generateProjectUnderwritingDocument(id: string, file_resource_id: number = null): Observable<any> {
    return this._httpClient
      .get<any>(
        `projects/${id}/generate_underwriting_model?account_id=${this._accountId}&file_resource_id=${file_resource_id}`,
      )
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }

  convertToPortfolio(id: string): Observable<any> {
    return this._httpClient.post<any>(`projects/${id}/convert_to_portfolio?account_id=${this._accountId}`, {}).pipe(
      map((response) => {
        return response.project;
      }),
    );
  }

  /**
   * Get projects within a distance of a given project
   * @param id
   */
  getNearbyProjects(id: string, radius: number = 25): Observable<Project[]> {
    return this._httpClient.get<any>(`projects/${id}/nearby?radius=${radius}&account_id=${this._accountId}`).pipe(
      map((response) => {
        return response.projects;
      }),
    );
  }

  deleteProject(id: string): Observable<any> {
    return this.projects$.pipe(
      take(1),
      switchMap((projects) =>
        this._httpClient.delete<any>(`projects/${id}?account_id=${this._accountId}`).pipe(
          map((response) => {
            // Find the index of the deleted label within the labels
            if (projects) {
              const index = projects.findIndex((item) => item.id == id);

              // Delete the label
              projects.splice(index, 1);

              // Update the labels
              this._projects.next(projects);
            }

            // Return the deleted status
            return response.project;
          }),
        ),
      ),
      finalize(() => this.projectChange(true)),
    );
  }

  /**
   * Upload Image to project
   */
  @CacheBuster({
    cacheBusterNotifier: projectCacheBuster$,
  })
  uploadImage(file: File): Observable<Image> {
    const uploadData = new FormData();
    uploadData.append('image[attachment]', file);

    return this.project$.pipe(
      take(1),
      switchMap((project) =>
        this._httpClient.post<any>(`images?account_id=${this._accountId}&project_id=${project.id}`, uploadData).pipe(
          map((response) => {
            let images = project.images;
            images.push(response?.image);
            project.images = images;
            this._project.next(project);
            return response;
          }),
        ),
      ),
    );
  }

  /**
   * Delete image
   */
  @CacheBuster({
    cacheBusterNotifier: projectCacheBuster$,
  })
  deleteImage(id: number): Observable<any> {
    return this.project$.pipe(
      take(1),
      switchMap((project) =>
        this._httpClient.delete<any>(`images/${id}?account_id=${this._accountId}`).pipe(
          map((response) => {
            let images = project.images;
            // Find the index of the deleted label within the labels
            const index = images.findIndex((item) => item.id === id);

            // Delete the label
            images.splice(index, 1);
            project.images = images;

            // Update the labels
            this._project.next(project);

            // Return the deleted status
            return response;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: projectCacheBuster$,
  })
  addProperty(property: Property, parentProject: Project = null): Observable<Property> {
    return (parentProject == null ? this.project$ : of(parentProject)).pipe(
      take(1),
      switchMap((project) =>
        this._httpClient
          .post<any>(`projects/${project.id}/properties?account_id=${this._accountId}`, { property: property })
          .pipe(
            map((response) => {
              let property = response.property;
              if (!project.properties) {
                project.properties = [];
                project.properties.unshift(property);
                // Update the contacts with the new contact
                if (parentProject == null) {
                  this._project.next(project);
                }
              } else {
                //project.deal = { id: null, properties: [] }

                if (!project.properties) {
                  project.properties = [];
                }
                project.properties.unshift(property);
                // Update the contacts with the new contact
                if (parentProject == null) {
                  this._project.next(project);
                }
              }

              // Return the new contact
              return response.property;
            }),
          ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: projectCacheBuster$,
  })
  updateProperty(property: Property): Observable<Property> {
    return this.project$.pipe(
      take(1),
      switchMap((project) =>
        this._httpClient
          .put<any>(`projects/${project.id}/properties/${property.id}?account_id=${this._accountId}`, {
            property: property,
          })
          .pipe(
            map((response) => {
              let updatedProperty = response.property;

              // Find the index of the updated comment
              const index = project.properties.findIndex((item) => item.id === updatedProperty.id);

              // Update the contact
              project.properties[index] = updatedProperty;

              return updatedProperty;
            }),
          ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: projectCacheBuster$,
  })
  deleteProperty(id: number): Observable<Property> {
    return this.project$.pipe(
      take(1),
      switchMap((project) =>
        this._httpClient.delete<any>(`projects/${project.id}/properties/${id}?account_id=${this._accountId}`).pipe(
          map((response) => {
            let properties = project.properties;
            const index = properties.findIndex((item) => item.id === id);
            properties.splice(index, 1);
            project.properties = properties;

            // Update the contacts with the new contact
            this._project.next(project);

            // Return the new contact
            return response.property;
          }),
        ),
      ),
    );
  }

  getAppointments(): Observable<Appointment[]> {
    return this.project$.pipe(
      take(1),
      switchMap((project) =>
        this._httpClient.get<any>(`appointments?account_id=${this._accountId}&project_id=${project.id}`).pipe(
          map((response) => {
            this._appointments.next(response.appointments);
            return response.appointments;
          }),
        ),
      ),
    );
  }

  updateAppointment(appointment: Appointment): Observable<Appointment> {
    return this.appointments$.pipe(
      take(1),
      switchMap((appointments) =>
        this._httpClient
          .put<any>(`appointments/${appointment.id}?account_id=${this._accountId}`, { appointment: appointment })
          .pipe(
            map((response) => {
              let updatedAppointment = response.appointment;
              // Find the index of the updated contact
              const index = appointments.findIndex((item) => item.id === updatedAppointment.id);

              // Update the contact
              appointments[index] = updatedAppointment;

              // Update the contacts
              this._appointments.next(appointments);

              // Return the updated contact
              return updatedAppointment;
            }),
          ),
      ),
    );
  }

  createAppointment(appointment: Appointment): Observable<Appointment> {
    return this.appointments$.pipe(
      take(1),
      switchMap((appointments) =>
        this._httpClient.post<any>(`appointments?account_id=${this._accountId}`, { appointment: appointment }).pipe(
          map((response) => {
            let newAppointment = response.appointment;
            appointments.push(newAppointment);
            this._appointments.next(appointments);
            return newAppointment;
          }),
        ),
      ),
    );
  }

  deleteAppointment(id: number): Observable<any> {
    return this.appointments$.pipe(
      take(1),
      switchMap((appointments) =>
        this._httpClient.delete<any>(`appointments/${id}?account_id=${this._accountId}`).pipe(
          map((response) => {
            // Find the index of the deleted label within the labels
            const index = appointments.findIndex((item) => item.id === id);

            // Delete the label
            appointments.splice(index, 1);

            // Update the labels
            this._appointments.next(appointments);

            // Return the deleted status
            return response.appointment;
          }),
        ),
      ),
    );
  }

  getContacts(project: Project = null): Observable<Contact[]> {
    return this.project$.pipe(
      take(1),
      switchMap((p) => this.getContactAssociations(p || project)),
    );
  }

  getContactAssociations(project: Project): Observable<Contact[]> {
    return this._httpClient
      .get<DealAssociatedContacts>(`projects/${project?.id}/contact_associations?account_id=${this._accountId}`)
      .pipe(
        map((response) => {
          const contacts = response.contact_associations.filter((ca) => ca.contact !== null).map((ca) => ca.contact);
          this._contacts.next(contacts);
          return contacts;
        }),
      );
  }

  getUserAssociationsGeneral(relatedToType: string, relatedToId: number): Observable<UserAssociation[]> {
    return this._httpClient
      .get<any>(
        `accounts/${this._accountId}/user_associations?related_to_type=${relatedToType}&related_to_id=${relatedToId}&account_id=${this._accountId}`,
      )
      .pipe(
        map((response) => {
          let user_associations = response.user_associations;

          return user_associations;
        }),
      );
  }

  createUserAssociationGeneral(userAssociation: UserAssociation): Observable<UserAssociation> {
    return this._httpClient
      .post<any>(
        `accounts/${this._accountId}/user_associations?related_to_type=${userAssociation.related_to_type}&related_to_id=${userAssociation.related_to_id}&account_id=${this._accountId}`,
        { user_association: userAssociation },
      )
      .pipe(
        map((response) => {
          let user_association = response.user_association;

          return user_association;
        }),
      );
  }

  deleteUserAssociationGeneral(userAssociation: UserAssociation): Observable<any> {
    return this._httpClient
      .delete<any>(
        `accounts/${this._accountId}/user_associations/${userAssociation.id}?user_id=${userAssociation.user.id}&related_to_type=${userAssociation.related_to_type}&related_to_id=${userAssociation.related_to_id}&account_id=${this._accountId}`,
      )
      .pipe(
        map((response) => {
          // Return the deleted status
          return response.user_association;
        }),
      );
  }

  getUserAssociations(): Observable<UserAssociation[]> {
    return this.project$.pipe(
      take(1),
      switchMap((project) =>
        this._httpClient
          .get<any>(
            `accounts/${this._accountId}/user_associations?related_to_type=Project&related_to_id=${project.id}&account_id=${this._accountId}`,
          )
          .pipe(
            map((response) => {
              // Update the labels
              //this._userAssociations.next(reponse.user_associations);

              let user_associations = response.user_associations;

              this._userAssociations.next(user_associations);

              // Return the deleted status
              return user_associations;
            }),
          ),
      ),
    );
  }

  createUserAssociation(user, projectId: string): Observable<UserAssociation> {
    const userAssociation = {
      related_to_id: projectId,
      related_to_type: 'Project',
      user_id: user.id,
      role: 'member',
    };
    return this.userAssociations$.pipe(
      take(1),
      switchMap((userAssociations) =>
        this._httpClient
          .post<any>(
            `accounts/${this._accountId}/user_associations?user_id=${user.id}&related_to_type=Project&related_to_id=${projectId}&account_id=${this._accountId}`,
            { user_association: userAssociation },
          )
          .pipe(
            map((response) => {
              let newUserAssociation = response.user_association;
              userAssociations.push(newUserAssociation);
              this._userAssociations.next(userAssociations);
              return newUserAssociation;
            }),
          ),
      ),
    );
  }

  deleteUserAssociation(userAssociation: UserAssociation, projectId: string): Observable<any> {
    return this.userAssociations$.pipe(
      take(1),
      switchMap((userAssociations) =>
        this._httpClient
          .delete<any>(
            `accounts/${this._accountId}/user_associations/${userAssociation.id}?user_id=${userAssociation.user.id}&related_to_type=Project&related_to_id=${projectId}&account_id=${this._accountId}`,
          )
          .pipe(
            map((response) => {
              // Find the index of the deleted label within the labels
              const index = userAssociations.findIndex((item) => item.id === userAssociation.id);

              // Delete the label
              userAssociations.splice(index, 1);

              // Update the labels
              this._userAssociations.next(userAssociations);

              // Return the deleted status
              return response.user_association;
            }),
          ),
      ),
    );
  }

  updateContactInfo(contact: Contact): Observable<Contact> {
    return this.contacts$.pipe(
      take(1),
      switchMap((contacts) =>
        this._httpClient
          .patch<any>(`contacts/${contact.id}?account_id=${this._accountId}`, {
            contact,
          })
          .pipe(
            map((response) => {
              if (contacts) {
                // Find the index of the updated contact
                const index = contacts.findIndex((item) => item.id === contact.id);

                // Update the contact
                contacts[index] = response.contact;

                // Update the contacts
                this._contacts.next(contacts);
              }
              return contact;
            }),
          ),
      ),
    );
  }

  createContactAssociation(contact: Contact, projectId: string): Observable<Contact> {
    const contactAssociation = {
      related_to_id: projectId,
      related_to_type: 'Project',
      contact: contact,
    };
    return this.contacts$.pipe(
      take(1),
      switchMap((contacts) =>
        this._httpClient
          .post<any>(`projects/${projectId}/contact_associations?account_id=${this._accountId}`, {
            contact_association: contactAssociation,
          })
          .pipe(
            map((response) => {
              let newContact = response.contact_association.contact;
              if (contacts) {
                contacts.unshift(newContact);
                this._contacts.next(contacts);
              }
              return newContact;
            }),
          ),
      ),
    );
  }

  deleteContactAssociation(contactId: number, projectId: string): Observable<any> {
    return this.contacts$.pipe(
      take(1),
      switchMap((contacts) =>
        this._httpClient
          .delete<any>(`projects/${projectId}/contact_associations/${contactId}?account_id=${this._accountId}`)
          .pipe(
            map((response) => {
              // Find the index of the deleted label within the labels
              const index = contacts?.findIndex((item) => item.id == contactId.toString());
              // Delete the label
              contacts?.splice(index, 1);
              // Update the labels
              this._contacts.next(contacts);

              // Return the deleted status
              return response;
            }),
          ),
      ),
    );
  }

  createContact(contact: Contact): Observable<Contact> {
    return this._httpClient.post<any>(`contacts?account_id=${this._accountId}`, { contact: contact }).pipe(
      map((response) => {
        return response.contact;
      }),
    );
  }

  getComments(): Observable<Comment[]> {
    return this.project$.pipe(
      take(1),
      switchMap((project) =>
        this._httpClient
          .get<any>(`comments?account_id=${this._accountId}&commentable_id=${project.id}&commentable_type=Project`)
          .pipe(
            map((response) => {
              this._comments.next(response.comments);
              return response.comments;
            }),
          ),
      ),
    );
  }

  /**
   * Create comment
   * @param comment
   */
  createComment(comment: Comment): Observable<Comment> {
    return this.comments$.pipe(
      take(1),
      switchMap((comments) =>
        this._httpClient.post<any>(`comments?account_id=${this._accountId}`, { comment: comment }).pipe(
          map((response) => {
            let newComment = response.comment;
            comments.unshift(newComment);
            this._comments.next(comments);
            return newComment;
          }),
        ),
      ),
    );
  }

  /**
   * Update comment
   * @param comment
   */
  updateComment(comment: Comment): Observable<Comment> {
    return this.comments$.pipe(
      take(1),
      switchMap((comments) =>
        this._httpClient.put<any>(`comments/${comment.id}?account_id=${this._accountId}`, { comment: comment }).pipe(
          map((response) => {
            let updatedComment = response.comment;

            // Find the index of the updated comment
            const index = comments.findIndex((item) => item.id === updatedComment.id);

            // Update the contact
            comments[index] = updatedComment;

            // Update the contacts
            this._comments.next(comments);

            return updatedComment;
          }),
        ),
      ),
    );
  }

  /**
   * Delete comment
   * @param id
   */
  deleteComment(id: number): Observable<any> {
    return this.comments$.pipe(
      take(1),
      switchMap((comments) =>
        this._httpClient.delete<any>(`comments/${id}?account_id=${this._accountId}`).pipe(
          map((reponse) => {
            // Find the index of the deleted label within the labels
            const index = comments.findIndex((item) => item.id === id);

            // Delete the label
            comments.splice(index, 1);

            // Update the labels
            this._comments.next(comments);

            // Return the deleted status
            return reponse.comment;
          }),
        ),
      ),
    );
  }

  getGeoJSON(url): Observable<any> {
    return this._httpClient.get<any>(url).pipe(
      map((response) => {
        this._geoJSON.next(response);
        return response;
      }),
    );
  }

  geocodeAddress(address: string): Observable<any> {
    return this._httpClient.get<any>(`deals/geocode?account_id=${this._accountId}&address=${address}`).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  @Cacheable({
    cacheBusterObserver: projectTemplatesCacheBuster$,
  })
  getProjectTemplates(accountId?: number): Observable<ProjectTemplate<any>[]> {
    return this._httpClient.get<any>(`project_templates?account_id=${accountId ?? this._accountId}`).pipe(
      map((response) => {
        this._projectTemplates.next(response.templates);
        return response.templates;
      }),
    );
  }

  getProjectTemplateById(id: number, reload: boolean = false): Observable<ProjectTemplate<any>> {
    return this.getProjectTemplateByIdBase(id).pipe(
      map((project_template) => {
        if (JSON.stringify(this._projectTemplate.value) === JSON.stringify(project_template)) {
          return this._projectTemplate.value;
        }
        this._projectTemplate.next(project_template);
        return project_template;
      }),
    );
  }

  getProjectTemplateByIdBase(id: number, accountId?: number): Observable<ProjectTemplate<any>> {
    return this._httpClient
      .get<{
        project_template: ProjectTemplate<any>;
      }>(`project_templates/${id}?account_id=${accountId || this._accountId}`)
      .pipe(map((response) => response.project_template));
  }

  getProjectTemplateVersions(id: number): Observable<ProjectTemplate<any>[]> {
    return this._httpClient.get<any>(`project_templates/${id}/versions?account_id=?account_id=${this._accountId}`).pipe(
      map((response) => {
        return response.versions;
      }),
    );
  }

  restoreProjectTemplateVersion(id: number, versionId: number): Observable<ProjectTemplate<any>[]> {
    return this._httpClient
      .post<any>(`project_templates/${id}/restore_version?account_id=${this._accountId}`, { version_id: versionId })
      .pipe(
        map((response) => {
          return response.version;
        }),
      );
  }

  getProjectTemplateByRelatedToId(
    relatedToId: number,
    relatedToType: string,
    account_id?: string,
  ): Observable<ProjectTemplate<any>> {
    return this._httpClient
      .get<{
        project_template: ProjectTemplate<any>;
      }>(
        `project_templates/by_related_to?related_to_id=${relatedToId}&related_to_type=${relatedToType}&account_id=${
          this._accountId || account_id
        }`,
      )
      .pipe(map((response) => response.project_template));
  }

  @CacheBuster({
    cacheBusterNotifier: projectTemplatesCacheBuster$,
  })
  createProjectTemplate(projectTemplate: ProjectTemplate<any>): Observable<ProjectTemplate<any>> {
    return this.projectTemplates$.pipe(
      take(1),
      switchMap((projectTemplates) =>
        this._httpClient
          .post<any>(`project_templates?account_id=${this._accountId}`, { project_template: projectTemplate })
          .pipe(
            map((response) => {
              let newProjectTemplate = response.project_template;
              projectTemplates.push(newProjectTemplate);
              this._projectTemplates.next(projectTemplates);
              return newProjectTemplate;
            }),
          ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: projectTemplatesCacheBuster$,
  })
  updateProjectTemplate(projectTemplate: ProjectTemplate<any>): Observable<ProjectTemplate<any>> {
    const project_template = { ...projectTemplate };
    delete project_template.rank;

    return this.projectTemplates$.pipe(
      take(1),
      switchMap((projectTemplates) =>
        this._httpClient
          .put<any>(`project_templates/${projectTemplate.id}?account_id=${this._accountId}`, {
            project_template,
          })
          .pipe(
            map((response) => {
              let updatedProjectTemplate = response.project_template;

              this._projectTemplate.next(updatedProjectTemplate);

              // Find the index of the updated comment
              const index = projectTemplates.findIndex((item) => item.id === updatedProjectTemplate.id);

              // Update the contact
              projectTemplates[index] = updatedProjectTemplate;

              // Update the contacts
              this._projectTemplates.next(projectTemplates);

              return updatedProjectTemplate;
            }),
          ),
      ),
    );
  }

  applyProjectTemplate(projectTemplateId: number, project_ids: number[]): Observable<ProjectTemplate<any>[]> {
    return this._httpClient
      .put<any>(`project_templates/${projectTemplateId}/apply?account_id=${this._accountId}`, {
        project_ids: project_ids,
      })
      .pipe(
        map((response) => {
          return response.project_template;
        }),
      );
  }

  uploadOm(document: File): Observable<Document> {
    const uploadData = new FormData();
    uploadData.append('document[attachment]', document);
    return this._httpClient.post<any>(`/projects/upload_om?account_id=${this._accountId}`, uploadData).pipe(
      map((response) => {
        return response.document;
      }),
    );
  }

  shareProject(
    projectId,
    permissions: any = {},
    isPublic: boolean = false,
    emails: string[] = [],
    message: string = '',
  ): Observable<any> {
    return this._httpClient
      .post<any>(`projects/${projectId}/share?account_id=${this._accountId}`, {
        permissions: permissions,
        public: isPublic,
        emails: emails.join(','),
        message: message,
      })
      .pipe(
        map((response) => {
          return response.shared_resources;
        }),
      );
  }

  relatePropertyToProject(property: Property, parentProject: Project = null): Observable<Property> {
    return (parentProject == null ? this.project$ : of(parentProject)).pipe(
      take(1),
      switchMap((project) =>
        this._httpClient
          .post<any>(
            `projects/${project.id}/relate_property?account_id=${this._accountId}&property_id=${property.id}`,
            { property: property },
          )
          .pipe(
            map((response) => {
              if (parentProject == null) {
                this._project.next(response.project);
              }

              return property;
            }),
          ),
      ),
    );
  }

  removePropertyToProjectRelationship(property: Property): Observable<Project> {
    return this.project$.pipe(
      take(1),
      switchMap((project) =>
        this._httpClient
          .put<any>(
            `projects/${project.id}/unrelate_property?account_id=${this._accountId}&property_id=${property.id}`,
            { property: property },
          )
          .pipe(
            map((response) => {
              this._project.next(response.project);

              return response.project;
            }),
          ),
      ),
    );
  }

  syncProjectWithProcore(
    projectId,
    permissions: any = {},
    isPublic: boolean = false,
    emails: string[] = [],
    message: string = '',
  ): Observable<any> {
    return this._httpClient
      .post<any>(`projects/${projectId}/procore/sync_project?account_id=${this._accountId}`, {})
      .pipe(
        map((response) => {
          return response.project;
        }),
      );
  }

  changePanelLayout(state) {
    this._changePanelState.next(state);
    return state;
  }

  exportPdf(state) {
    this._exportPdf.next(state);
    return state;
  }

  projectChange(state) {
    this._changeProjectState.next(state);
    return state;
  }

  addPanelDefinition(panelDef) {
    this._panelDefinition.next(panelDef);
    return panelDef;
  }

  setNextProjectValue(project: Project): void {
    this._project.next(project);
  }

  createPortfolio(project: Project, selectFields: string): Observable<Project> {
    const payload: { project: Project; select?: string } = { project };

    if (selectFields) {
      payload.select = selectFields;
    }

    return this._httpClient
      .post(`projects?account_id=${this._accountId}`, payload)
      .pipe(map(({ project }: { project: Project }) => project));
  }

  getVersionsByProjectId(projectId: number): Observable<Project[]> {
    return this._httpClient.get<Project[]>(`projects/${projectId}/versions?account_id=${this._accountId}`);
  }

  getKanban(
    filter: KanbanFilter,
    nestedOptions: { fieldDefs: FieldDef[] } = null,
  ): Observable<{ deals: Project[]; lanesCount: Dictionary<number> }> {
    let params = new HttpParams({ fromObject: filter as any });
    params = params.delete('fjson').delete('viewId').delete('fields');
    const fjsonObj = JSON.parse(filter.fjson ?? '[]') as any[];

    if (!filter.isDynamicView) {
      fjsonObj.push(['pipeline_id', 'in', [filter.viewId]]);
    }

    if (nestedOptions) {
      return this.graphqlService
        .query<Project>(Number(this._accountId), {
          connection: GraphqlConnection.projectsKanban,
          fields: filter.fields,
          fieldDefs: nestedOptions.fieldDefs,
          filter: {
            fjson: JSON.stringify(fjsonObj),
            pagination: { page: 1, size: filter.per_page },
            query: filter.q,
            sort: [{ field: 'updated_at', order: 'desc' }],
            filterDictionary: filter.filterDictionary,
          },
          group: filter.group,
          options: { keepConnectionCase: true, bucketsCount: true },
        })
        .pipe(
          map(({ edges, bucketsCount }) => {
            const deals = edges.map(({ node }) => node);

            this._projects.next(deals);

            return { deals, lanesCount: bucketsCount as Dictionary<number> };
          }),
        );
    }

    return this.graphqlService
      .query<Project>(Number(this._accountId), {
        connection: GraphqlConnection.projectsKanban,
        fields: filter.fields,
        filter: {
          fjson: JSON.stringify(fjsonObj),
          pagination: { page: 1, size: filter.per_page },
          query: filter.q,
          sort: [{ field: 'updated_at', order: 'desc' }],
          filterDictionary: filter.filterDictionary,
        },
        group: filter.group,
        options: { keepConnectionCase: true, bucketsCount: true },
      })
      .pipe(
        map(({ edges, bucketsCount }) => {
          const deals = edges.map(({ node }) => node);

          this._projects.next(deals);

          return { deals, lanesCount: bucketsCount as Dictionary<number> };
        }),
      );
  }

  getMoreForKanban(params: GraphqlParams): Observable<Project[]> {
    return this.graphqlService.query<Project>(Number(this._accountId), params).pipe(
      map((result: GraphqlResultInfo<Project>) => {
        const projects = result.edges.map(({ node }) => node);
        const data = [...this._projects.value, ...projects];
        this._projects.next(data);
        return projects;
      }),
    );
  }

  updateProjectSubjectValue(projects: Project[]): void {
    this._projects.next(projects);
  }

  extractFormFieldsFromTemplate(projectTemplate: ProjectTemplate<any>): any[] {
    let inputFields = [];
    for (let row of projectTemplate?.definition?.dashboard.panels) {
      for (let panel of row) {
        if (panel?.type == 'form') {
          for (let fieldRow of panel.meta?.fields) {
            for (let field of fieldRow) {
              inputFields.push(field);
            }
          }
        }
      }
    }

    for (let tab of projectTemplate?.definition?.dashboard.tabs) {
      for (let panel of tab.panels) {
        if (panel?.type == 'form') {
          for (let fieldRow of panel.meta?.fields) {
            for (let field of fieldRow) {
              inputFields.push(field);
            }
          }
        }
      }
    }

    return inputFields;
  }

  transformProjectsForTree(projects: any[], chunkedProjectsArray: any[], groupByField: string): any[] {
    const flatTree: any[] = [];
    const groupedProjects = new Set();

    let formattedGroupByField = this.groupFieldValue(groupByField);
    formattedGroupByField = `${formattedGroupByField}.name`;

    function addProjectToTree(project: any, parentPath: string[] = []) {
      if (!project) return;

      let path = [...parentPath];

      if (project.parent_id) {
        path.push(project.id.toString());
      } else {
        path = [...parentPath, project.id.toString()];
      }

      flatTree.push({
        ...project,
        path: path,
        isProject: true,
      });

      const children = projects.filter((p) => p.parent_id?.toString() === project.id.toString());
      if (children.length > 0) {
        children.forEach((child: any) => {
          addProjectToTree(child, path);
        });
      }
    }

    const groups = chunkedProjectsArray.filter((group) => group.summaryColumns);

    groups.forEach((group) => {
      flatTree.push({
        ...group,
        isCategory: true,
        id: group.label,
        name: group.label,
        path: [group.label],
      });

      const groupProjects = projects.filter((project) => {
        const projectTypeId = project[groupByField];
        const projectTypeName = this.getNestedValue(project, formattedGroupByField);

        return projectTypeId === group.label || projectTypeName === group.label;
      });

      groupProjects.forEach((project) => {
        if (!project.parent_id) {
          addProjectToTree(project, [group.label]);
          groupedProjects.add(project.id);
        }
      });
    });

    const otherProjects = projects.filter((project) => !groupedProjects.has(project.id) && !project.parent_id);

    if (otherProjects.length > 0) {
      otherProjects.forEach((project) => {
        if (!project.parent_id) {
          addProjectToTree(project, ['Other']);
        }
      });
    }

    return flatTree;
  }

  groupFieldValue(fieldKeyValue: string): string {
    return fieldKeyValue.replace('_id', '');
  }

  getNestedValue(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }
}
