import { Component, OnInit } from '@angular/core';

import { isObjectEqual } from 'app/shared/Utils/common.utils';
import { FormFieldComponent } from '../form-field.types';

@Component({
  templateUrl: './select-form-field.component.html',
  styleUrls: ['./select-form-field.component.scss'],
})
export class SelectFormFieldComponent extends FormFieldComponent implements OnInit {
  private _originalValue: string | string[];
  private options: any[];
  private _readonly: boolean;

  public filteredOptions: any[];
  public value: string | string[];
  public editing: boolean = false;
  public multiple: boolean = false;
  public multiSelectOptionsText: string = '';

  ngOnInit(): void {
    super.ngOnInit();

    this.value = this.model[this.fieldDef.name];

    this._originalValue = this.value;

    this.multiple = this.fieldDef.meta?.multi_select;

    if (this.fieldDef?.meta?.options?.length > 0) {
      const options = this.fieldDef.meta.options;
      this.options = options.map((value) => ({ id: value, name: value }));
    } else {
      this.options = this.data.options[this.fieldDef.meta.option_name];

      if (this.fieldDef.name === 'template_id') {
        this.options = this.options.filter((x) => x.related_to_type === this.fieldDef.related_to_type);
      }

      if (this.fieldDef.meta.option_name == 'pipelines') {
        this.options = this.options.filter((pipeline) => pipeline?.pipeline_type != 'dynamic');
      }

      this.options = this.options.filter((option) => {
        if (option.hidden) {
          return false;
        } else {
          if (option?.pipeline_ids) {
            if (this.model.pipeline_id) {
              return option?.pipeline_ids.includes(Number(this.model.pipeline_id));
            }
          }
        }
        return true;
      });
    }

    if (this.fieldDef.meta && this.fieldDef.meta.readonly) {
      this._readonly = this.fieldDef.meta.readonly;
    }

    if (this.readonly) {
      this._readonly = this.readonly;
    }

    this.readonly = this._readonly;

    this.filteredOptions = this.options;
    try {
      if (Array.isArray(this.value)) {
        this.multiSelectOptionsText = this.value.map((option) => option).join(', ');
      }
    } catch (e) {}
  }

  private onSave(): void {
    if (!isObjectEqual(this._originalValue, this.value)) {
      this.updateModel();
    }
  }

  private updateModel(): void {
    this.model[this.fieldDef.name] = this.value;
    this._originalValue = this.value;
    this.update.emit({ [this.fieldDef.name]: this.value });
  }

  edit(): void {
    if (!this.editing && !this._readonly) {
      this.editing = true;
    }
  }

  onSelectionChange(): void {
    this.onSave();
  }

  openedChange(isOpening: boolean): void {
    if (!isOpening) {
      this.onSave();
      this.editing = false;
    }
  }

  onBlur(): void {
    this.onSave();
    this.editing = false;
  }

  /**
   * When the model is updated after save, re-run our logic
   * @param model
   */
  updateChangedModel(model: any): void {
    this.model = model;
    this.value = this.model[this.fieldDef.name];

    if (!isObjectEqual(this._originalValue, this.value)) {
      this._originalValue = this.value;
    }
  }
}
