import { Route } from '@angular/router';
import { AuthGuard, InvitationGuard, NoAuthGuard, RoleGuard } from 'app/core/guards';
import { LayoutComponent } from 'app/layout/layout.component';
import { EmptyLayoutComponent } from 'app/layout/layouts/empty/empty.component';
import { SharingLayoutComponent } from 'app/layout/layouts/sharing/sharing.component';
import { InitialDataResolver, AccountResolver } from 'app/app.resolvers';
import { ProjectDialogComponent } from 'app/modules/projects/project-dialog/project-dialog.component';
import {
  ProjectsFieldDefsResolver,
  ProjectsProjectResolver,
  PipelinesPipelineResolver,
  ProjectDataResolver,
} from 'app/modules/projects/projects.resolvers';
import { AuthorizationGuard } from './core/guards/authorization.guard';
import { Module } from './core/enums';
import { ModernLayoutComponent } from './layout/layouts/horizontal/modern/modern.component';
import { RelatedToType } from '@shared/enums';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';

export const APP_PRIVATE_ROUTES = [
  {
    path: 'unauthorized',
    loadChildren: () =>
      import('app/shared/components/unauthorized/unauthorized.module').then((m) => m.UnauthorizedModule),
  },
  {
    path: 'contacts',
    data: { module: Module.CONTACTS },
    canActivate: [AuthorizationGuard],
    loadChildren: () => import('app/modules/contacts/contacts.module').then((m) => m.ContactsModule),
  },
  {
    path: 'companies',
    data: { module: Module.CONTACTS },
    canActivate: [AuthorizationGuard],
    loadChildren: () => import('app/modules/companies/companies.module').then((m) => m.CompanyModule),
  },
  {
    path: 'projects',
    data: { module: Module.DEALS },
    canActivate: [AuthorizationGuard],
    loadChildren: () => import('app/modules/projects/projects.module').then((m) => m.ProjectsModule),
  },
  {
    path: 'research',
    data: { module: Module.MAPS },
    canActivate: [AuthorizationGuard],
    loadChildren: () => import('app/modules/research/research.module').then((m) => m.ResearchModule),
  },
  {
    path: 'calendar',
    loadChildren: () => import('app/modules/calendar/calendar.module').then((m) => m.CalendarModule),
  },
  {
    path: 'tasks',
    data: { module: Module.TASKS, taskModuleRelatedTo: RelatedToType.Account },
    canActivate: [AuthorizationGuard],
    loadChildren: () => import('app/modules/aggregated-tasks/aggregated-tasks.module').then((m) => m.LegacyTasksModule),
  },
  {
    path: 'folders',
    loadChildren: () => import('app/modules/folders/folders.module').then((m) => m.FoldersModule),
  },
  {
    path: 'settings',
    data: { module: Module.SETTINGS },
    canActivate: [AuthorizationGuard],
    loadChildren: () => import('app/modules/settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'reports',
    data: { module: Module.REPORTS },
    canActivate: [AuthorizationGuard],
    loadChildren: () => import('app/modules/reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('app/modules/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'dashboard',
    data: { module: Module.DASHBOARDS },
    canActivate: [AuthorizationGuard],
    loadChildren: () => import('app/modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'properties',
    data: { module: Module.PROPERTIES },
    canActivate: [AuthorizationGuard],
    loadChildren: () => import('app/modules/properties/properties.module').then((m) => m.PropertiesModule),
  },
  {
    path: 'reporting',
    data: { module: Module.REPORTS },
    canActivate: [AuthorizationGuard],
    loadChildren: () => import('app/modules/reporting/reporting.module').then((m) => m.ReportingModule),
  },
  {
    path: 'objects',
    data: { fromRoot: true },
    loadChildren: () =>
      import('app/shared/modules/custom-objects/custom-objects.module').then((m) => m.CustomObjectsModule),
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];

// @formatter:off
/* eslint-disable max-len */
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'accounts' },

  // Redirect signed in user to the '/example'
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'accounts' },

  // Auth routes
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: EmptyLayoutComponent,
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import('app/modules/auth/confirmation-required/confirmation-required.module').then(
            (m) => m.AuthConfirmationRequiredModule,
          ),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('app/modules/auth/forgot-password/forgot-password.module').then((m) => m.AuthForgotPasswordModule),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then((m) => m.AuthResetPasswordModule),
      },
      {
        path: 'sign-in',
        loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then((m) => m.AuthSignInModule),
      },
      {
        canActivate: [InvitationGuard],
        path: 'sign-up',
        loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then((m) => m.AuthSignUpModule),
      },
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: EmptyLayoutComponent,
    children: [
      {
        path: 'sign-out',
        loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then((m) => m.AuthSignOutModule),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.module').then((m) => m.AuthUnlockSessionModule),
      },
    ],
  },

  // Shared resources
  {
    path: '',
    component: SharingLayoutComponent,
    children: [
      {
        path: 'shared',
        loadChildren: () =>
          import('app/modules/shared-resources/shared-resources.module').then((m) => m.SharedResourcesModule),
      },
    ],
  },

  // Admin routes
  {
    path: 'accounts',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: ModernLayoutComponent,
    data: { fromAccounts: true },
    children: [
      { path: '', loadChildren: () => import('app/modules/accounts/accounts.module').then((m) => m.AccountsModule) },
    ],
  },
  {
    path: 'approvals',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: { fromAccounts: true },
    children: [
      {
        path: '',
        loadChildren: () => import('app/shared/modules/approvals/approvals.module').then((m) => m.ApprovalsModule),
      },
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard, RoleGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
      account: AccountResolver,
    },
    children: APP_PRIVATE_ROUTES,
  },
  {
    path: 'pd/:id/:project_id',
    component: ProjectDialogComponent,
    outlet: 'modal',
    resolve: {
      pipeline: PipelinesPipelineResolver,
      project: ProjectsProjectResolver,
      options: ProjectDataResolver,
      fieldDefs: ProjectsFieldDefsResolver,
    },
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];
