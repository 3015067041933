import { NgModule } from '@angular/core';
import { ReadableFieldPipe } from './readable-field.pipe';
import { CustomFilterPipe } from './custom-filter.pipe';
import { FormatNumberPipe } from './format-number.pipe';
import { SafePipe } from './safe.pipe';
import { LinkifyPipe } from './linkify.pipe';
import { PhonePipe } from './phone.pipe';
import { OperatorToDisplayStringPipe } from './operator-to-display-string.pipe';
import { SortPipe } from './sort.pipe';
import { HtmlSanitizerPipe } from './html-sanitizer.pipe';
import { SortByPipe } from './sort-by.pipe';
import { FilterByPipe } from './filter.pipe';
import { FormatTaskTitlePipe } from './format-task-title.pipe';
import { TsCurrencyPipe } from './ts-currency.pipe';
import { FieldSearchPipe } from './field-definition-search.pipe';
import { RelatedToTypeTitlePipe } from './related-to-type.pipe';
import { TaskFilterPipe } from './task-filter.pipe';
import { PluralizePipe } from './pluralize.pipe';
import { FormatSnakeCasePipe } from './format-snake-case.pipe';
import { HighlighterPipe } from './highlighter.pipe';
import { SplicerPipe } from './splicer.pipe';
import { ArrayToStringPipe } from './array-to-string.pipe';
import { FieldNameToLabelPipe } from './field-name-to-label.pipe';
import { MutateToAnyPipe } from './mutate-to-any.pipe';
import { GroupByPipe } from './group-by.pipe';
import { ZeroToNullPipe } from './zero-to-empty.pipe';
import { RemoveZeroDecimalsPipe } from './remove-zero-decimals.pipe';
import { IsFormControlPipe } from './is-form-control.pipe';
import { CountTrueFromObjectPipe } from './count-true-from-object.pipe';
import { StripHtmlPipe } from './strip-html.pipe';
import { CustomFieldRelationshipFilter } from './custom-objects.pipe';
import { FormatDatePipe } from './format-date.pipe';
@NgModule({
  imports: [],
  declarations: [
    FormatNumberPipe,
    FormatDatePipe,
    RemoveZeroDecimalsPipe,
    SafePipe,
    FieldSearchPipe,
    LinkifyPipe,
    PhonePipe,
    OperatorToDisplayStringPipe,
    SortPipe,
    FormatSnakeCasePipe,
    CustomFilterPipe,
    HtmlSanitizerPipe,
    ReadableFieldPipe,
    SortByPipe,
    FilterByPipe,
    FormatTaskTitlePipe,
    TsCurrencyPipe,
    RelatedToTypeTitlePipe,
    TaskFilterPipe,
    PluralizePipe,
    HighlighterPipe,
    SplicerPipe,
    ArrayToStringPipe,
    FieldNameToLabelPipe,
    MutateToAnyPipe,
    GroupByPipe,
    FieldNameToLabelPipe,
    ZeroToNullPipe,
    GroupByPipe,
    IsFormControlPipe,
    CountTrueFromObjectPipe,
    StripHtmlPipe,
    CustomFieldRelationshipFilter,
  ],
  exports: [
    FormatNumberPipe,
    FormatDatePipe,
    RemoveZeroDecimalsPipe,
    SafePipe,
    FieldSearchPipe,
    LinkifyPipe,
    PhonePipe,
    OperatorToDisplayStringPipe,
    SortPipe,
    FormatSnakeCasePipe,
    CustomFilterPipe,
    HtmlSanitizerPipe,
    ReadableFieldPipe,
    SortByPipe,
    FilterByPipe,
    FormatTaskTitlePipe,
    TsCurrencyPipe,
    RelatedToTypeTitlePipe,
    TaskFilterPipe,
    PluralizePipe,
    HighlighterPipe,
    SplicerPipe,
    ArrayToStringPipe,
    FieldNameToLabelPipe,
    MutateToAnyPipe,
    GroupByPipe,
    FieldNameToLabelPipe,
    ZeroToNullPipe,
    GroupByPipe,
    IsFormControlPipe,
    CountTrueFromObjectPipe,
    StripHtmlPipe,
    CustomFieldRelationshipFilter,
  ],
  providers: [
    FormatNumberPipe,
    SafePipe,
    FieldSearchPipe,
    LinkifyPipe,
    PhonePipe,
    OperatorToDisplayStringPipe,
    SortPipe,
    FormatSnakeCasePipe,
    CustomFilterPipe,
    HtmlSanitizerPipe,
    ReadableFieldPipe,
    SortByPipe,
    FilterByPipe,
    FormatTaskTitlePipe,
    TsCurrencyPipe,
    RelatedToTypeTitlePipe,
    TaskFilterPipe,
    PluralizePipe,
    HighlighterPipe,
    SplicerPipe,
    ArrayToStringPipe,
    FieldNameToLabelPipe,
    MutateToAnyPipe,
    GroupByPipe,
    FieldNameToLabelPipe,
    ZeroToNullPipe,
    GroupByPipe,
    IsFormControlPipe,
    StripHtmlPipe,
    CustomFieldRelationshipFilter,
  ],
})
export class PipesModule {}
