import { Pipe, PipeTransform } from '@angular/core';
import { CustomObject, CustomObjectLinkHash } from '@shared/modules/custom-objects/custom-objects.types';

/**
 * Filter array of objects based on:
 * The object selected in the 'Object Type' to avoid redundant field relationship.
 * Objects that has an associated ID value (Custom Objects only).
 * Only objects that shares a relationship with the selected one.
 */
@Pipe({ name: 'coRelationshipFilter' })
export class CustomFieldRelationshipFilter implements PipeTransform {
  transform(values: CustomObject[], objectName: string): CustomObject[] {
    const selectedObject = values.find((obj) => obj.name === objectName);

    if (!selectedObject || !selectedObject.custom_object_links) {
      return [];
    }

    const linkedIds = selectedObject.custom_object_links.map((link: CustomObjectLinkHash) => Object.keys(link)[0]);

    const result = values.filter(
      (obj) => obj.name !== objectName && obj.id !== null && linkedIds.includes(obj.id.toString()),
    );

    return result;
  }
}
